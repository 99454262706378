#welcome-message {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  box-shadow: 0 3px 7px rgba(0,0,0,.25);
  box-sizing: border-box;
  transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  z-index: 503;

  &:target {
    opacity: 1;
    visibility: visible;
  }

  .modal-content {
    width: 1200px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 510;

    @media (max-width: 1500px) {
      width: 80%;
    }
  }

  .copy {
    padding: 1.5em;
    color: #363636;
    background: white;
    border-radius: 3px;
    border: 1px solid $orange;

    a {
      display: inline-block;
      padding: 10px 20px;
      color: white;

      @media (max-width: $screen-xs) {
        padding: 5px 20px;
      }

      &#modal-continue {
        padding-left: 0;
      }
    }

    @media (max-width: $screen-xm) {
      padding: 0;
    }
  }

  .overlay {
    background-color: #000;
    background: rgba(0,0,0,.8);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 505;
  }

  .coc-buttons {
    border-radius: 5px;
    border: 1px solid white;

    > a {
      color: white;
    }
  }

  .welcome-message-grid-container {
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 5px;

    @media (max-width: 1500px) {
      grid-template-columns: unset;
    }
  }

  .welcome-message-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    .welcome-message-banner-container {
      position: relative;
      height: 100%;
      background-image: url(/sites/default/files/w055-0455-019.jpg);
      background-size: cover;

      @media (max-width: $screen-xm) {
        height: auto !important;
        background-image: unset;
      }

      @media (max-width: $screen-sm) {
        width: 100%;
        height: 200px;
        background-size: auto;
      }

      @media (max-width: 1500px) {
        width: 100%;
        height: 215px;
        background-size: auto;
      }
    }

    .welcome-message-banner-caption {
      position: absolute;
      top: 15px;
      left: 5%;

      @media (max-width: $screen-xm) {
        display: none;
      }
    }

    .welcome-message-banner-caption-header {
      font-size: 38px;
      font-weight: 600;
    }

    .welcome-message-banner-caption-paragraph {
      font-size: 16px;
    }

    .welcome-message-banner-logo {
      float: right;
      width: 200px;

      @media (max-width: $screen-xm) {
        width: 100%;
        position: relative;
        top: unset;
        right: unset;
      }
    }

    .welcome-message-new-supporter {
      height: 248px;
      background-color: #FF6600;

      @media (max-width: $screen-xm) {
        max-width: unset;
        height: 180px;
      }

      @media (max-width: 1500px) {
        max-width: unset;
      }

      & > div {
        margin-left: 10%;
        margin-right: 10%;
        padding-top: 15px;

        @media (max-width: $screen-xm) {
          padding-top: 20px;
        }
      }

      & h2 {
        @media (max-width: $screen-xm) {
          display: none;
        }
      }

      .welcome-message-new-supporter-header {
        font-size: 2.3rem;
        margin-bottom: 0;
      }

      .coc-guest-block {
        display: inline-block;
        float: left;

        @media (max-width: $screen-xm) {
          display: block;
          float: unset;
          text-align: center;
          margin-bottom: 10px;
        }

        @media (max-width: $screen-xs) {
          display: block;
          float: unset;
        }

        & > img {
          @media (max-width: $screen-xm) {
            display: none !important;
          }
        }
      }

      .coc-supporter-block {
        display: inline-block;
        float: right;

        @media (max-width: $screen-xm) {
          display: block;
          float: unset;
          text-align: center;
          margin-bottom: 10px;
        }

        @media (max-width: $screen-xs) {
          display: block;
          float: unset;
        }

        & > img {
          @media (max-width: $screen-xm) {
            display: none !important;
          }
        }
      }
    }

    .welcome-message-join {
      max-width: unset;
      background-color: #FF6600;
      margin-top: 4px;

      @media (max-width: 1500px) {
        height: auto;
      }

      & > div {
        margin-left: 10%;
        margin-right: 10%;
        padding-top: 25px;
        padding-bottom: 25px;

        @media (max-width: $screen-xm) {
          padding-top: 20px;
          padding-bottom: 20px;
        }

        @media (max-width: 1500px) {
          margin-left: 10%;
          margin-right: 10%;
        }

        & > div {
          display: inline-flex;
          justify-content: center;

          @media (max-width: $screen-xm) {
            display: unset;
            margin-bottom: unset;
          }

          & > div:last-child {
            color: white;
            margin-left: 20px;

            @media (max-width: $screen-xm) {
              margin-left: unset;
            }
          }
        }
      }

      & img {
        @media (max-width: $screen-xm) {
          display: none;
        }
      }

      .welcome-message-join-header {
        margin-bottom: 0px;
        font-weight: 600;
        margin-bottom: 10px;

        @media (min-width: $screen-xm) {
          margin-bottom: unset;
        }
      }

      .welcome-message-join-caption {
        display: none;

        @media (min-width: $screen-xm) {
          display: block;
        }
      }
    }
  }
}

#wvsg-coc-be-a-partner-form {
  &> div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: $red;
  }

  .form-item > input {
    background: #FF6600;
    color: white;
    width: 100%;

    &::placeholder {
      color: white;
    }
  }

  .form-item-wrapper {
    .error-message {
      font-size: 0.7rem;
    }

    &:has(div.form-item):has([name="phone"]) {
      display: none;
    }
  }

  .form-submit {
    background-color: white;
    color: #FF6600;
    width: 100%;
  }

  @include breakpoint(sm) {
    &> div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      grid-template-areas:
        "form-area-1 form-area-2"
        "form-area-3 form-area-4"
        "form-area-5 form-area-6";
      column-gap: 10px;
      row-gap: 5px;
    }
  }
}

#wvsg-coc-be-a-partner-form-block {
  color: $black;

  .title {
    width: fit-content;
    margin: 4px auto 0;
    text-align: center;

    h2 {
      font-weight: bold;
      border-style: solid;
      border-color: $white;
      border-width: 1px 1px 0 1px;
      border-radius: 10px 10px 0 0;
      padding: 5px 40px;
      background-color: $orange;
    }
  }

  .block-content {
    display: flex;
    flex-direction: column;

    .block-text {
      align-content: center;
      font-size: 1rem;
      flex-basis: 50%;
      text-align: center;

      @include breakpoint(md) {
        text-align: initial;
      }
    }

    h2 {
      font-size: 1.6875rem;
      font-weight: bold;
      line-height: 120%;

      @include breakpoint(md) {
        text-align: left;
      }
    }
  }

  @include breakpoint(md) {
    .block-content {
      flex-direction: row;
      gap: 3rem;
    }
  }

  ul {
    padding-left: 1rem;
  }

  form {
    flex-basis: 50%;

    &> div {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    .form-item {
      &> input {
        width: 100%;
        height: 40px;
        background: $wv-very-light-grey !important;
        color: $black;
        border-radius: 8px;
        border: 1px solid $wv-grey;

        &::placeholder {
          color: $wv-grey;
        }
      }
    }

    .form-submit {
      background-color: $orange;
      color: $white;
      width: 100%;
      height: 40px;
    }

    @include breakpoint(md) {
      &> div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        grid-template-areas:
          "form-area-1 form-area-2 form-area-3"
          "form-area-4 form-area-4 form-area-4"
          "form-area-5 form-area-5 form-area-5";
        column-gap: 20px;
        row-gap: 10px;
      }

      .form-item {
        display: inline-block;
      }
      .form-item-wrapper {
        .error-message {
          font-size: 0.7rem;
        }
        &> div {
          width: 100%;
        }
      }

      .form-item-wrapper:nth-child(1) {
        grid-area: form-area-1;
      }

      .form-item-wrapper:nth-child(2) {
        grid-area: form-area-2;
      }

      .form-item-wrapper:nth-child(3) {
        grid-area: form-area-3;
      }

      .form-item-wrapper:nth-child(4) {
        grid-area: form-area-4;
      }

      .form-submit {
        grid-area: form-area-5;
        justify-self: end;
      }
    }
  }
}

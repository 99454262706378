.node-type-adp {
  .panel-row-2 {
    background: #eee;
    text-align: center;
    .row-inner {
      padding: 2rem 4rem;
    }
  }
}

.node-adp.ds-2col {
  .field-name-title-field {
    display: none;
  }
  .field-label-above .field-label {
    margin-bottom: 0.3rem;
    font-size: $h3-font-size;
  }
  table {
    border: 1px solid #eee;
    td {
      vertical-align: top;
      text-align: left;
    }
    th {
      text-align: left;
    }
  }

  .group-left {
    @include full;
    @include breakpoint(sm) {
      @include span(6 of 12);
      clear: none;
    }
  }
  .group-right {
    @include full;
    .field-name-field-location {
      margin-bottom: 1rem;
    }
    .field-name-field-images {
      margin-bottom: 1rem;
      img {
        width: 100%;
        height: inherit;
      }
    }
    @include breakpoint(sm) {
      @include span(6 of 12 last);
      clear: none;
    }
  }
}

.section-blog {
  .feed-icon {
    display: none;
  }
  .taxonomy-term-description {
    font-size: $h4-font-size;
  }
}
.page-home-page {
  .node-blog{
    .field-name-field-blog-category {
      margin: unset;
      padding: unset;
      border-left: unset;
      font-size: $h4-font-size;
    }  
  }
}
.node-blog {
  border-bottom: 1px solid #ccc;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  .field-name-post-date {
    color: $secondary-font-color;
    display: inline-block;
    margin-bottom: 1rem;
  }

  .field-name-field-blog-category {
    display: inline-block;
    margin: 0 0 0 0.5rem;
    padding: 0 0 0 0.5rem;
    border-left: 1px solid #ccc;
  }

  .field-name-field-written-by {
    font-weight: bold;
    font-style: italic;
  }

  @include breakpoint(sm) {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.node-teaser, &.view-mode-homepage_teaser {
    border-bottom: 1px solid $mid-grey;
    margin-bottom: 2rem;
    .group-left {
      @include full;
      @include breakpoint(xm) {
        @include span(3 of 9);
        clear: none;
      }
      img {
        width: 100%;
        height: inherit;
      }
    }
    .group-right {
      @include full;
      @include breakpoint(xm) {
        @include span(6 of 9 last);
        clear: none;
      }
      h3 {
        margin: 0;
      }
      .field-name-post-date {
        margin-bottom: 0.5rem;
        color: $secondary-font-color;
      }
      iframe {
        display: none;
      }
      .field-name-node-link {
        @include breakpoint(sm) {
          margin: 1.5rem 0 2rem;
        }

        a {
          @extend .cta-transparent;
        }
      }
    }
  }

  &.view-mode-homepage_teaser {
    margin-bottom: 0;

    @include breakpoint(xm) {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }

    .field-name-body {
      @include breakpoint(xm) {
        display: none;
      }
      @include breakpoint(md) {
        display: block;
      }
    }
  }

  &.view-mode-card {
    border: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.75rem;
    > * {
      flex-grow: 0;
      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
    }
    .field-name-field-images {
      margin-bottom: 1.5rem;
      img {
        aspect-ratio: 4 / 3;
        object-fit: cover;
        border-radius: 0.75rem;
      }
    }
    .field-name-field-blog-category {
      border: 0;
      padding: 0;
      margin-left: 0;
      text-transform: uppercase;
      font-size: 0.75rem;
      @include breakpoint(sm) {
        font-size: 0.875rem;
      }
    }
    .field-type-text-with-summary {
      flex-grow: 5;
    }
    h5 {
      margin: 0;
    }
    .field-name-body p {
      font-size: 0.875rem;
    }
  }
}

// Blog details page
.node-type-blog {
  img {
    max-width: 100%;
  }
  h1.title {
    margin-bottom: 0;
  }
  /* Style h2 to look like h1 instead of having to change .tpl */
  h2.block-title {
    margin-top: 0;
    line-height: 120%;
    font-size: $h1-font-size;
  }
  .field-name-field-written-by {
    margin-bottom: 1rem;
    clear: both;
  }
  .field-name-field-images {
    img {
      width: 100%;
      height: inherit;
      @include breakpoint(xm) {
        width: inherit;
        float: left;
        margin: 0 1rem 1rem 0;
      }
    }
  }
  #content {
    #block-views-blogs-block-1 {
      padding-top: 2rem;

      @include breakpoint(lg) {
        width: 75rem;        
      }
    }

    .view-display-id-block_1 {
      .view-content {
        @include breakpoint(lg){
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: 30px;
        }
      }

      .views-row {
        height: 100%;
        color: $black;

        h3 {
          margin: 0;
        }

        img {
          height: 210px;
          background: $mid-grey;

          @include breakpoint(lg) {
            width: 100%;
          }
        }

        .views-field-view-node a {
          @extend .cta-transparent;
        }

        .views-field.views-field-field-images {
          width: 360px;
          height: 210px;
          border: 1px solid lightgray;
        }

        .views-field.views-field-title {
          flex-grow: 1;
        }

        .views-field.views-field-created {
          color: rgba(0,0,0,0.54);
        }

        .views-field.views-field-view-node {
          padding-top:  10px;
          padding-bottom: 10px;
          flex-shrink: 2;
        }

        @include breakpoint(lg) {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          row-gap: 5px;
        }
      }
    }
  }
}

.view-blogs.carousel-success-stories {
  .view-content {
    .slick--view--blogs.slick--optionset--carousel-3-blog {
      @include breakpoint(md) {
        padding-bottom: 3.5rem;
      }
      .slick__slider {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        /* padding up to sm follows that of container__lr--md */
        padding-left: 5%;
        @include breakpoint(sm) {
          padding-left: 2.5%;
          margin-left: -0.75rem;
          margin-right: -0.75rem;
        }
        @include breakpoint(md) {
          padding: 0 8.625rem;
        }
        .slick__slide {
          .node-blog.view-mode-card {
            height: 100%;
          }
        }
        .slick-list {
          @include breakpoint(sm) {
            padding: 0;
            margin: 0;
          }
        }
        .slick-track {
          margin-left: calc((280px - 100vw) / 2);
          @include breakpoint(sm) {
            margin-left: 0;
          }
        }
      }
      .slick__arrow {
        left: 2.9rem;
        right: 2.9rem;
      }
    }
  }
}

.mc-modal {
	visibility: inherit !important;
}

.mc-banner {
	visibility: hidden !important;
}

#block-mailchimp-signup-mailchimp-subscribe-form {
	z-index: 9999999;
	width: 320px;
	background: $light-grey;
	position: absolute;
	top: 75px;
	padding: 10px;
	border-radius: 10px;
	box-sizing: border-box;
	display: none;
	.mailchimp-newsletter-mergefields {
		float: left;
	}
	.form-item-mergevars-EMAIL {
		input {
			width: 300px;
		}
	}
	.form-item-mergevars-FNAME {
		width: 185px;
		float: left;
		margin-right: 10px;
		input {
			width: 185px;
		}
	}
	.form-item-mergevars-LNAME {
		width: 95px;
		float: left;
		margin-right: 10px;
		input {
			width: 100px;
		}
	}
	.form-submit {
		margin-top: 10px;
		margin-left: 5px;
		width: 290px;
		padding: 7px 12px;
	}
	 @media (max-width: $screen-sm) {
		top: 120px;
	}
}

// ADD TO CART CONFIRMATION
// --------------------------------------------------
div.messages.commerce-add-to-cart-confirmation {
  background-image: none;
  border-color: $orange;
  color: $black;
  position: fixed;
  width: 90%;
  max-width: 50rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-left: 0 !important;
  display: block;

  .message-inner {
    .added-product-title {
      color: $orange;
      font-size: $h3-font-size;
      width: auto;
      @include breakpoint(md){
        width:60%;
      }
    }
    .button-wrapper {
      box-sizing: border-box;
      background: #F1EFEF;
      z-index: 999;
      padding: 20px 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;

      .button{
        display: inline-block;
        &.checkout {
          a {
            display: inline-block;
            @extend .cta-orange;
            margin: 0;
            box-sizing: border-box;
            width: 12rem;
          }
        }
        &.continue {
          a {
            display: inline-block;
            background: $light-grey;
            border-radius: 8px;
            margin: 0;
            box-sizing: border-box;
            width: 12rem !important;
            &:hover {
              background: $mid-grey;
            }
          }
        }
      }

      &::after {
        display: none;
      }
    }
    .commerce-add-to-cart-confirmation-close{
      display: none;
    }
  }
  .commerce-product-sku,
  .commerce-product-title-label,
  .commerce-product-status {
    display: none;
  }
  .view-confirm-message-product-display {
    box-sizing: border-box;
    width: auto;
    @media (min-width: 968px) {
        width: 65%;
      }
    .view-content {
      width: 100%;
    }
  }
  .view-confirm-message-product-display .view-content .field .views-label {
    color: $secondary-font-color;
    font-size: 1rem;
  }

  .view-confirm-message-product-display .view-content .views-field-commerce-total {
    margin: 20px 0;
    border-top: 1px solid #ccc;
    padding-top: 5px;
  }
  .view-confirm-message-product-display .view-content .views-field-commerce-total .field-content {
    color: $orange;
  }
}

// GIFT BASKET ON NAVIGATION
// --------------------------------------------------
#block-wvsg-shop-gift-basket-link {
  align-content: center;
  height: 44px;
  color: #fff;
  text-align: right;
  border-radius: 5px 5px 0 0;
  z-index: 500;

  @include breakpoint(md) {
    height: initial;
    align-content: initial;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
  .block-inner {
    position: relative;
    padding: 0.5rem 0;
  }
  span {
    white-space: nowrap;
  }
  .giving-basket {
    position: relative;
    min-width: 32px;
    height: 24px;
    &:before {
      position: absolute;
      display: block;
      margin-right: 0.2rem;
      background: url('../images/basket.svg') center center no-repeat;
      content: '';
      width: 24px;
      height: 24px;
      top: 50%;
      left: 0.5rem;
      transform: translate(0, -50%);
    }
  }
  .giving-basket__quantity {
    display: block;
    background: $wv-blue;
    border-radius: 2rem;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    font-size: 0.6rem;
    position: absolute;
    top: 0;
    left: 1.5rem;
    border: 2px solid $orange;
  }
}

// GIFT BASKET BLOCK
//-----------------------------------------
#block-wvsg-shop-gift-basket-block,
.pane-wvsg-shop-gift-basket-block {
  border: 1px solid $black;
  padding: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  .pane-title {
    @include breakpoint(sm) {
      margin-bottom: 0.5rem;
    }
  }
  a {
    text-transform: uppercase;
    position: absolute;
    top: 1.5rem;
    right: 1rem;

    @include breakpoint(sm) {
      position: inherit;
      margin-bottom: 1rem;
      display: inline-block;
    }
    @include breakpoint(md) {
      position: absolute;
    }
  }
  table {
    width: 100%;
  }
  th {
    background: $orange;
    text-align: left;
    padding: 0.5rem;
    font-weight: bold;
  }
  th:nth-child(2) {
    text-align: right;
  }
  td {
    padding: 0.5rem;
    border-bottom: 1px solid $mid-grey;
  }
  td:nth-child(2) {
    text-align: right;
  }
  tr:last-child {
    font-weight: bold;
  }
  td.line-item-title, td.line-item-price {
    border-top: 1px solid $black;
    border-bottom: none;
    font-weight: bold;
    font-size: $h3-font-size;
  }
  .line-item-title {
    text-align: right;
    padding-right: 0.5rem;

    &:after {
      content: ":";
    }
  }
}

// GIFT BASKET PAGE
// --------------------------------------------------

.section-cart {
  .page__title {
    display: none;
  }
}

.view-commerce-cart-form {
  box-sizing: border-box;

  .view-header {
    text-align: center;
  }

  h2 {
    margin: 0 0 1rem;
    font-size: $h1-font-size;
  }

  table {
    caption {
      font-size: $h3-font-size;
      margin-bottom: 0.5em;
    }
    &.recurring-group {
      margin-bottom: 0;
    }
  }

  .Sponsorship-item,
  .Donation-item {
    .views-field-edit-quantity input[id*="quantity"] {
      display: none;
    }
  }

  #wvsg-shop-recurring-option-form {
    background: $light-grey;
    padding: 1rem;
    margin-bottom: 1rem;
    text-align: right;

    input#edit-update {
      display: none;
    }

    br {
      display: none;
    }
    .form-wrapper {
      display: block;
    }
    .form-item {
      margin: 0;
    }
    .field-name-field-sponsorship-type {
      margin-right: 1rem;
    }
    label {
      display: block;
      @include breakpoint(xm) {
        display: inline;
      }
    }
    p {
      margin: 0;
    }
    @extend %clearfix;
  }
  .views-table {

    tbody {
      border-top: 2px solid $black;
      border-bottom: 2px solid $black;
    }

    tr {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      border-bottom: 1px solid $mid-grey;

      @include breakpoint(xm) {
        flex-wrap: nowrap;
      }
    }

    th {
      padding-left: 0;
      padding-right: 0;
    }
    td {
      padding: 1rem 0 0;
      @include breakpoint(xm) {
        padding: 1rem 0;
      }
    }

    th, td {
      &:first-child {
        flex-grow: 1000;
        width: calc((100vw - 3.75rem) - (100vw - 100%));
        @include breakpoint(xm) {
          width: auto;
        }
      }
      &:nth-child(2) {
        flex-grow: 0;
        flex-shrink: 0;
        width: 3.75rem;
        @include breakpoint(md) {
          width: 8rem;
        }
      }
      &:last-child {;
        flex-grow: 10;
        width: 100%;
        flex-shrink: 0;
        @include breakpoint(xm) {
          flex-grow: 0;
          width: 8rem;
          padding-right: 1rem;
        }
      }
    }
    th:last-child {
      display: none;
      @include breakpoint(xm) {
        display: block;
      }
    }
    td:last-child {
      padding: 0 0 1rem;
      @include breakpoint(xm) {
        padding: 1rem 1rem 1rem 0;
      }
    }

    /* Megarow */
    .megarow {
      td {
        display: block;
        width: 100% !important;
        padding-right: 0 !important;
      }
      .views-megarow-content {
        position: relative;
        padding-top: 0;
        .form-item label {
          float: none;
          margin-right: 0.4rem;
        }
      }
      .megarow-header {
        background-color: transparent;
        position: absolute;
        top: 0.5rem;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        margin-right: 0;

        a.close {
          margin-right: 0.3rem;
        }
      }
    }
  }
  .views-field-field-image {
    display: none;
    @include breakpoint(xm) {
      display: table-cell;
      padding-right: 0.5rem;
    }
  }
  .views-field-line-item-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include breakpoint(xm) {
      font-size: $h3-font-size;
      flex-direction: row;
    }
    .line-item__image {
      margin-right: 1rem;

      img {
        width: 150px;
        height: 100px;
        object-fit: cover;
        aspect-ratio: unset;
        @include breakpoint(xm) {
          height: 150px;
        }
      }
    }
    .line-item__details {
      margin-right: 1rem;
    }
  }
  .delete-line-item {
    text-align: center;
    background: none;
    color: $orange;
    margin: 0;
    padding: 0;
  }
  .views-field-edit-quantity {
    text-align: center;
    width: 1.5rem;
    .form-item {
      margin: 0;
      padding: 0;
    }
    input {
      text-align: center;
    }
  }
  .form-actions {
    text-align: right;

    #edit-submit {
      @extend .cta-transparent--plain;
      margin-bottom: 1.5rem;
    }
  }
  .views-field-commerce-total {
    text-align: right;
  }
  .recurring-group th.views-field-edit-quantity {
    color: $orange;
  }

  .line-item-summary {
    padding: 0.5rem 0;
    border-bottom: 2px solid $black;
  }

  .line-item-total {
    font-weight: bold;
    font-size: $h3-font-size;
  }
  .commerce-line-item-actions {
    > * {
      text-align: center;
      display: block;
      margin: 0 auto 1rem !important;

      @include breakpoint(xm) {
        text-align: right;
        display: inline-block;
        margin: 0 0 1rem 1rem !important;
      }

    }
    .anonymous-checkout-help {
      display: block;
      font-size: 0.75em;
    }
  }

  /* Gift card */
  form {
    .group-giftcard {
      margin-bottom: 1rem;
      padding: 0;
      border: none;

      .fieldset-title {
        display: none;
      }

      .fieldset-legend {
        color: $primary-font-color;
      }

      input {
        width: 100%;
      }

      .form-textarea-wrapper {
        margin: 0 !important;

        textarea {
          resize: vertical;
        }
      }

      .date-combo {
        margin: 1rem 0 0;
        padding: 0;

        .date-padding {
          padding: 0;

          > * {
            margin-right: 1.5rem;
          }
        }
      }
    }
    .form-submit + .form-submit {
      margin-left: 1rem;
    }

  }
}

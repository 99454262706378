// DONATION GRID LISTING
//-----------------------------------------

.view-donation-catalogue.view-display-id-panel_pane_related_donation,
.view-donation-catalogue.view-display-id-panel_pane_donation {
  .view-content {
    @include breakpoint(xm) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 15px;
    }

    @include breakpoint(md) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 15px;
    }
  }
  .views-row {
    border: 1px solid $mid-grey;
    margin-bottom: 1rem;

    >div {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 100%;

      .field-name-title-field {
        flex-grow: 1;
      }

      .field-name-field-story-text {
        flex-grow: 1;
      }

      .field-name-node-link {
        flex-shrink: 2;
      }
    }
    img {
      width: 100%;
      height: inherit;
    }
    .field-name-title-field {
      h3 {
        margin: 0.5rem 1rem;
        font-size: $h3-font-size;
          a {
            color: $black;
          &:hover {
            color: $orange;
          }
        }
      }
    }
    .field-name-field-story-text {
      margin: 0 1rem;
    }
    .field-type-commerce-product-reference {
      display: none;
    }
    .field-name-node-link {
      margin: 1.5rem 1rem;
    }
  }
}

// FEATURE DONATION
//-----------------------------------------
.view-donation-catalogue.view-display-id-panel_pane_donation .attachment {
  @extend %clearfix;
  border-bottom: 1px solid black;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;

  .views-row {
    @include full;
    border: none;
    margin: 0;
    padding: 0;
    .field-name-title-field {
      margin: 0;
      padding: 0;
      h3 {
        font-size: $h2-font-size;
        margin: 0 0 0.5rem !important;
      }
    }

    .field-name-field-story-text {
      margin: 0;
    }
    .field-name-node-link {
      margin: 1.5rem 0;
    }
    .group-left {
      width: 100%;
      @include breakpoint(sm) {
        @include span(6 of 12);
        margin-bottom: 1rem;
      }
      img {
        width: 100%;
        height: inherit !important;
      }
    }
    .group-right {
      width: 100%;
      @include breakpoint(sm) {
        @include span(6 of 12 last);
      }
    }
  }
}

// DONATION CATEGORY LISTING
//-----------------------------------------
.page-ways-to-give-sector-funding {
  .panel-col-last {
    .pane-views-f40086fbaf3521ef727d1e546df9c7b7 {
      .pane-title {
        font-size: $h3-font-size;
        margin: 0;
      }
      .form-submit {
        margin-top: 0;
      }
    }
  }
}
.pane-donation-catalogue-panel-pane-donation-listing {
  .pane-title {
    font-size: $h3-font-size;
  }
}

.view-donation-catalogue.view-display-id-panel_pane_donation_listing {
  .views-row {
    margin-bottom: 0.5rem;
    a {
      color: $secondary-font-color;
      &.active {
        color: $orange;
      }
    }
  }
}

// DONATION NODE DETAILS
//-----------------------------------------
.node-type-donation {
  .panel-col-first {
    @include full;
    @include breakpoint(xm) {
      @include full;
    }
    @include breakpoint(sm) {
      @include span(9 of 12);
    }
  }
  .panel-col-last {
    @include breakpoint(xm) {
      @include full;
    }
    @include breakpoint(sm) {
      @include span(3 of 12 last);
      clear: none;
    }
  }
}

.node-donation {
  .group-left {
    width: 100%;
    margin-bottom: 1rem;
    @include breakpoint(xm) {
      @include span(6 of 12);
    }
    img {
      width: 100%;
      height: inherit !important;
    }
  }
  .group-right {
    width: 100%;
    @include breakpoint(xm) {
      @include span(6 of 12 last);
    }
  }
}

.field-name-field-recurring-donation-option,
.field-name-field-donation-amount {
  margin: 0 0 1rem;
  label:first-child:not([for="edit-line-item-fields-field-donation-amount-und-other"]) {
    display: block;
    font-weight: bold;
    font-size: $h3-font-size;
    margin-bottom: 0.5rem;
  }
  .form-type-radio {
    padding: 0;
    margin-bottom: 0.5rem;
  }
}

.pane-donation-catalogue-panel-pane-related-disaster-donations,
.pane-donation-catalogue-panel-pane-related-donation {
  border-top: 1px solid $mid-grey;
  margin-top: 2rem;
  padding-top: 2rem;
}

/* Style the quantity field */
.wvsg-new-child-sponsorship .form-item.form-type-textfield.form-item-quantity label,
.wvsg-pair-for-me .form-item.form-type-textfield.form-item-quantity label {
  display: block;
  font-weight: bold;
  font-size: $h3-font-size;
  margin-bottom: 0.5rem;
}

/* Style the text field */
.node-type-donation .field-name-field-comment {
  margin-bottom: 1rem;
  textarea {
    margin-top: 0.5rem;
  }
}

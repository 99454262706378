.climate-change-campaign {
  display: none;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  height: 100vh;
  width: 100%;
  background: black;
  vertical-align: middle;
  transition: opacity 1s ease-in;

  &.shown {
    opacity: 1;
  }

  .climate-change-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    margin: 0 auto;
  }

  .climate-change-close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 10001;
    color: white;
    opacity: 0.5;
    font-size: 35px;
    font-weight: bold;
    padding: 0.4em 0.55em;
  }

  .climate-change-overlay a {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
  }

  video {
    max-height: 100vh;
    max-width: 100vw;
    margin: 0 auto;
    display: block;
  }

  .climate-change-unmute {
    background: url('../images/speaker.png') no-repeat;
    background-size: contain;
    cursor: pointer;
    width: 8vw;
    height: 8vw;
    max-width: 15vh;
    max-height: 15vh;
    position: absolute;
    left: 50%;
    top: 85%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    transition: opacity 1s ease-in;
    opacity: 0.5;

    &.mobile {
      width: 20vw;
      height: 20vw;
      max-width: 130px;
      max-height: 130px;
    }

    &.hidden {
      opacity: 0;
    }
  }
}

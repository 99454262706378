// @file
// SASS partials import
// --------------------------------------------------
@import "../bower_components/normalize-css/normalize.css";
// @import "compass/support";
// @import "compass/typography/vertical_rhythm";
// @import "compass/css3";
// @import "compass/utilities";
@import "../bower_components/breakpoint-sass/stylesheets/breakpoint";
@import "../bower_components/susy/sass/susy";

$susy: (
  container: 75rem,
  columns: 12,
  math: fluid,
  output: float,
  gutters: 30px/70px,
  gutter-position: after,
  box-sizing: border-box,
  debug: (
    image: hide,
    color: rgba(#66f, .25),
    output: background,
    toggle: top left,
  ),
);

// Mixins and Variables
// --------------------------------------------------
@import "global/fonts";
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
@import "global/font-awesome/font-awesome";
@import "global/variables";
@import "global/mixin";

// COMPONENTS SPECIFIC STYLES
// --------------------------------------------------
@import "partials/base";
@import "partials/layout";
@import "partials/header";
@import "partials/navigation";
@import "partials/forms";
@import "partials/pagination";
@import "partials/flexslider";
@import "partials/zen";
@import "partials/footer";
@import "partials/colorbox";
@import "partials/newsletter";
@import "partials/slick";

// PAGE SPECIFIC STYLES
// --------------------------------------------------
@import "pages/aboutus";
@import "pages/adp";
@import "pages/blog";
@import "pages/cancel";
@import "pages/careers";
@import "pages/cart";
@import "pages/checkout";
@import "pages/chosen";
@import "pages/needs_impact";
@import "pages/getinvolved";
@import "pages/giftcatalogue";
@import "pages/donationcatalogue";
@import "pages/homepage";
@import "pages/media-card";
@import "pages/media-card-listing";
@import "pages/sponsor";
@import "pages/trips";
@import "pages/user";
@import "pages/waystogive";
@import "pages/climatechange";
@import "pages/welcomemessage";

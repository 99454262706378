// PRE-HEADER
// --------------------------------------------------
#skip-link {
  margin: 0;
  padding: 0;
}

#header-above {
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  overflow: hidden;
}

.region-header {
  @extend %clearfix;

  a {
    color: $black;
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
      color: $orange;
      text-decoration: none;
    }
  }
  ul {
    width: max-content;
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    padding-right: 0.875rem;
    margin-right: 1rem;
    display: inline-block;
    &.last {
      border: none;
      margin-right: 0;
      padding-right: 0;
    }
  }
}

#block-system-user-menu {
  font-size: 0.875rem;

  .menu {
    display: flex;
    align-items: center;
    height: 100%;
  }

  li.first {
    border: none;
    padding: 0;

    &:not(.last) {
      margin-right: 0.5rem;
      @include breakpoint(sm) {
        margin-right: 1rem;
      }
    }
    a {
      color: $orange;
      text-decoration: underline;
      display: inline-block;
    }
  }
}

#block-locale-language {
  @include span(3 of 12 last);
  text-align: right;
  @include breakpoint(xm) {
    @include span(3 of 12);
  }
  @include breakpoint(sm) {
    margin-top: 5px;
  }
  @include breakpoint(md) {
    @include span(isolate 2 at 2);
  }
  @include breakpoint(lg) {
    @include span(isolate 2 at 3);
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
    border: 0;
    margin: 0;
    padding: 0;

    @include breakpoint(xm) {
      display: inline-block;
      border-right: 1px solid $mid-grey;
      margin-right: 0.5rem;
      padding-right: 0.875rem;
    }

    a.active {
      color: $orange;
    }
  }
}

#block-search-form {
  display: none;
  @include breakpoint(sm) {
    display: block;
    @include span (4 of 12 last);
    form {
      text-align: right;
    }
  }
  @include breakpoint (sm) {
    @include span(3 of 12 last);
  }
  @include breakpoint (lg) {
    @include span(2 of 12 last);
  }
  .form-item .form-text {
    padding: 5px;
  }
  .form-submit {
    background: url('../images/search.png') $orange no-repeat center center;
    text-indent: -9999px;
    padding: 7px 15px;
  }
  .container-inline {
    width: 100%;
    .form-item {
      display: inline-block;
      vertical-align: top;
      width: 80%;
      input {
        width: 100%;
      }
    }
    .form-actions {
      display: inline-block;
      vertical-align: top;
      width: auto;
    }
  }
}

.block-mailchimp-signup {
  form#mailchimp-signup-subscribe-block-mailchimp-subscribe-form-form {
    .form-item-wrapper {
      &:has(div.form-item):has([name="phone"]) {
        display: none;
      }
    }
  }
}

// BRANDING
// --------------------------------------------------

.header__site-name {
  text-indent: -9999px;
  background: url('../wvsg_logo.svg') no-repeat center;
  background-size: contain;
  width: 124px;
  height: 38px;
  // @include span(8 of 12 last);
  a {
    display: block;
    height: 100%;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    background: url('../wvsg_logo.svg') no-repeat right;
    background-size: 100%;
  }

  @include breakpoint(sm) {
    width: 192px;
    height: 59px;
  }
}

.header.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  @include breakpoint(sm) {
    padding: 20px 0;
  }
  &:after {
    display: none;
  }
}

.header__name-and-slogan {
  flex: auto;
  .header__site-slogan {
    width: fit-content;
    font-style: italic;
    text-align: left;
    display: table;
    border-right: 1px solid #c4c4c4;
    @include breakpoint(xm) {
      margin-right: 10px;
      display: table;
      float: right;
      border-right: 1px solid #c4c4c4;
    }
    .anniv_emblem {
      padding-right: 0.1rem;
      display: table-cell;
      vertical-align: middle;
      &> img {
        height: 70px;
        width: 70px;
      }
    }
    .slogan {
      padding-right: 0.5rem;
      display: table-cell;
      vertical-align: middle;
    }
  }
}

// HELLO BAR
// --------------------------------------------------
.closeblock {
  padding-top: 0.4rem;
  padding-right: 0;
}

.closeblock-button {
  cursor: pointer;
}

.hellobar {
  background-color: #FED732;
  margin: -0.5rem -6% 0.4rem;
  padding: 0.4em 0;

  @include breakpoint(md) {
    margin-left: calc(-50vw + 30rem);
    margin-right: calc(-50vw + 30rem);
  }

  @include breakpoint(lg) {
    margin-left: calc(-50vw + 37.5rem);
    margin-right: calc(-50vw + 37.5rem);
  }

  > div {
    max-width: 80%;
    text-align: center;
    margin: 0 auto;
    font-size: 0.875rem;

    @include breakpoint(md) {
      max-width: 60rem;
    }
    @include breakpoint(lg) {
      max-width: 75rem;
    }
  }
}

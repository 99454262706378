.node-type-needs-and-impact {
  #main {
    padding-bottom: 0;
  }
  #content {
    @include full;
    max-width: inherit;
  }
  .row-inner {
    @extend .container;
    overflow: hidden;
  }
  h1.page__title {
    @extend .container;
    text-align: center;
  }
  .admin-functions {
    @extend .container;
  }
  .panel-row-1 {
    .image-column-3 {
      display: block;
      text-align: center;
      @include breakpoint(md) {
        display: flex;
        justify-content: space-between;
      }
      .image {
        margin-top: 20px;
        display: table;
        img {
          height: 140px;
          max-width: unset;
        }
        .icon {
          display: table-cell;
          width: 50%;
        }
        .description {
          display: table-cell;
          vertical-align: middle;
          width: 200px;
          color: $orange;
          position: relative;
          @include breakpoint(md) {
            width: 50%;
          }
          .title {
            display: block;
            font-size: $h2-font-size;
            font-weight: bold;
          }
        }
      }
    }
    .image-report {
      display: block;
      @include breakpoint(md) {
        display: flex;
        justify-content: space-evenly;
      }
      img {
        display: block;
        margin: auto;
        margin-top: 20px;
      }
    }
    .group-header {
      @extend %clearfix;
      border-bottom: 1px solid $mid-grey;
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;
    }
    .field-name-field-card-image {
      img {
        width: 100%;
        height: inherit;
      }
      @include breakpoint(sm) {
        @include span(6 of 12);
      }
    }
    .field-name-body {
      @include breakpoint(sm) {
        @include span(6 of 12 last);
      }
    }
    .group-left {
      @include full;
      @include breakpoint(sm) {
        @include span(6 of 12);
        clear: none;
      }
    }
    .group-right {
      @include full;
      @include breakpoint(sm) {
        @include span(6 of 12 last);
        clear: none;
      }
    }
    .field-name-field-needs-video {
      margin-bottom: 1.5rem;
    }
    .field-name-field-our-response {
      border-top: 1px solid $mid-grey;
      padding-top: 1.5rem;
    }
  }
  
  .panel-row-5 {
    padding: 1.5rem 0;
    .view-wvsg-landing {
      .field-name-title a {
        &:hover {
          color: $orange;
        }
      }
      .field-name-field-card-link a {
        @extend .cta-transparent;
      }
    }
  }

  .view-wvsg-landing {
    .view-content {
      @include breakpoint(lg) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;
      }

      .views-row {
        width: 100%;
        margin: 0;

        &> div {
          height: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;

          &> div {
            flex-basis: 100%;
          }

          .field-name-field-card-image {
            height: 210px;

            img {
              width: unset;
              height: 210px;
            }
          }

          .field-name-field-card-link {
            margin-bottom: 2rem;
            flex-shrink: 2;

            @include breakpoint(lg) {
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }

  /* Style h2 to look like h1 instead of having to change .tpl */
  h2.pane-title {
    margin-top: 0;
    line-height: 120%;
    font-size: $h1-font-size;
  }
}
// Slick slider with slick_default optionset and default skin
// -----------------------------------------------------------

%slick__slider--default {

  margin: 0 -0.8rem;

  @include breakpoint(lg) {
    margin: 0 calc(50px - 0.8rem);
  }

  .slick-slide {
    margin: 0 0.8rem;
  }

  .slide__caption {
    text-align: center;
    padding: 20px 0;

    .slide__link {
      a {
        @extend .cta-orange;
      }
      margin-bottom: 1.5rem;
    }
  }
}

%slick__arrow {
  top: 40%;
  transform: translate(0, -50%);
}

.slick--default {
  .slick__slider {
    @extend %slick__slider--default;
  }
  .slick__arrow {
    @extend %slick__arrow;
  }
}

.media-card {
  max-width: 395px;
  margin: 0 auto;
  text-align: center;
  border-radius: 0.75rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  background: $white;

  &__media {
    aspect-ratio: 16 / 9;
    width: 100%;
    border-radius: 0.75rem;
    overflow: hidden;

    > * {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      aspect-ratio: 16 / 9;
      object-fit: cover;
    }
  }

  &__details {
    padding: 1.2rem;
    flex-grow: 3;
    display: flex;
    flex-direction: column;
  }

  .field-name-title h3 {
    font-size: 1.5rem;
    line-height: 1.33;
    font-weight: bold;

    @include breakpoint(md) {
      font-size: 1.94rem;
      line-height: 1.16;
    }
  }

  .field-name-field-media-card-description {
    flex-grow: 3;
  }

  .field-name-field-media-card-cta {
    margin: 1rem 0 0;
    a {
      @extend .cta-orange;
      display: inline-block;
    }
  }

  &:not(:has(> .media-card__media)) {
    background: $wv-very-light-grey;
  }
}

html {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}

body {
  color: $primary-font-color;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}

.container {
  @include container(90%);
  @include breakpoint(sm) {
    @include container(95%);
  }
  @include breakpoint(md) {
    @include container(60rem);
  }

  @include breakpoint(lg) {
    @include container();
  }
}

body.panels-ipe {
  margin-bottom: 0 !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-weight: normal;
}

h1, h1.page__title, h2.page__title {
  margin-top: 0;
  line-height: 120%;
  font-size: $h1-font-size;
}

/* Hide the page title when header-banner pane is detected in the page. */
h1.page__title:has(~ .contextual-links-region .header-banner),
h1.page__title:has(~ .panel-display .header-banner) {
  display: none;
}

h2 {
  font-size: $h2-font-size;
  line-height: 130%;
  margin: 0 0 1rem;
}

h3 {
  font-size: $h3-font-size;
  line-height: 130%;
  margin: 0 0 0.5rem;
}

h2, h3 {
  a {
    color: $black;
    &:hover {
      color: $orange;
    }
  }
}

/* Override heading size within #main for any pages with body.font-2024 class */
body.font-2024 #main {
  h1, h2.page__title {
    font-size: 2.125rem;
    line-height: 2.5rem;
    font-weight: bold;
    @include breakpoint(sm) {
      font-size: 3.438rem;
      line-height: 3.75rem;
    }
  }
  h2 {
    font-size: 1.875rem;
    line-height: 2rem;
    font-weight: bold;
    @include breakpoint(sm) {
      font-size: 2.438rem;
      line-height: 2.75rem;
    }
  }
  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: bold;
    @include breakpoint(sm) {
      font-size: 1.938rem;
      line-height: 2.25rem;
    }
  }
  h4 {
    font-size: 1.188rem;
    line-height: 1.75rem;
    font-weight: bold;
    @include breakpoint(sm) {
      font-size: 1.563rem;
      line-height: 1.875rem;
    }
  }
  h5 {
    font-size: 1.063rem;
    line-height: 1.5rem;
    font-weight: bold;
    @include breakpoint(sm) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
  h6 {
    font-size: 0.938rem;
    line-height: 1.25rem;
    font-weight: bold;
    @include breakpoint(sm) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

p {
  margin: 0 0 1rem;
}

a {
  color: $orange;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

blockquote {
  border-left: 1px solid $mid-grey;
  margin: 1rem;
  padding-left: 1.5rem;
}

button,
input,
optgroup,
select,
textarea {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}

// Default table styles
table {
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;

  thead {
    background: $orange;
  }
  th {
    background: $orange;
    font-weight: bold;
    color: $white;
    padding: 3px;
  }
  tbody {
    td {
      padding: 3px;
    }
  }
}

// Alternative table styles with different row colors
.table-alt {
  tr:nth-child(even) {
    background: #FFEFCE;
  }
}

.table-wrapper {
  overflow-x: auto;
  border-right: 1px solid #ccc;

  @include breakpoint(sm) {
    border: 0;
  }
}

// Fix truncated tables on smaller screens
@include breakpoint(xxs) {
  table#projects {
    display: block;
    overflow: scroll;
  }
}


// CONTEXTUAL MENU FIX
// --------------------------------------------------

.pane-views-panes, .pane-main-menu, .pane-block {
  .contextual-links-wrapper {
    right: 30px;
  }
}

.views-row .contextual-links-wrapper {
  right: 60px;
}

// ACTION LINKS FOR CLONE BUTTON
// --------------------------------------------------
.action-links {
  padding: 0;

  li {
    list-style: none;
    display: inline-block;
  }
  a {
    @extend .cta-transparent;
  }
}

// MENU BLOCKS ON THE SIDE
// --------------------------------------------------
.block-menu-block,
.view-taxonomy-listing,
.panel-pane .menu-block-wrapper {
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    padding: 0;
    margin: 0 0 1rem;
  }
  a {
    color: #636363;
    &:hover,
    &.active {
      color: $orange;
      text-decoration: none;
    }
  }
}

// IFRAME VIDEO FILTER
// --------------------------------------------------
.video-filter {
  margin-bottom: 1rem;
  @include breakpoint(md) {
    text-align: center;
  }
  iframe {
    width: 100%;
  }
}


// TABS
// --------------------------------------------------

.tabs-container {
  width: 100%;
  margin: 2rem auto;

  ul.tabs {
    text-transform: uppercase;
    margin: 0 0 2rem;
    padding: 0px;
    list-style: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }

    * {
      box-sizing: border-box;
    }
  }
  ul.tabs li {
    position: relative;
    background: none;
    color: $wv-grey;
    display: inline-block;
    padding: 2px 0;
    margin: 0 15px;
    cursor: pointer;
    border-bottom: 4px solid transparent;
    text-align: center;
    white-space: nowrap;

    &:first-child {
      margin-left: 0;
    }

    &:not(:last-child)::after {
      content: "";
      position: absolute;
      width: 1px;
      top: 0;
      bottom: 0;
      right: -17px;
      background-color: $wv-dark-grey;
    }
  }

  ul.tabs li.current{
    border-bottom: 4px solid $wv-orange;
    color: $black;
  }

  .tab-content{
    display: none;
    box-sizing: border-box;
    padding: 0;
  }

  .tab-content.current{
    display: inherit;
  }
}

//Cookie Banner
.sliding-popup-bottom{
  position: sticky;
}

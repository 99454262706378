.front:not(.home-2024) {
  &.no-sidebars #content {
    @include full;
    max-width: inherit;
  }
  .row-inner {
    @extend .container;
    overflow: hidden;
  }
  .panel-row-3 {
    background: $light-grey;
  }
  .panel-row-1 .row-inner {
    padding-top: 1rem;
    @include breakpoint(xs) {
      max-width: 100%;
    }
  }
  .panel-row-2 {
    background: $orange;
  }
  .panel-row-5 {
    padding: 2rem 0;
  }
}

.front, .home-2024 {
  #main {
    padding: 0;
  }
  .breadcrumbs {
    display: none;
  }
}

body:not(.home-2024) {

  // HOMEPAGE BANNER
  //------------------------------------------

  .pane-home-page-banners-panel-pane-homepage-banner {
    @include breakpoint(lg) {
      @include span(9 of 12);
      border-bottom: 0;
      margin: 0;
    }
    .flexslider {
      border: 0;
    }

    border-bottom: 1px solid $mid-grey;
    margin-bottom: 2rem;
  }

  // SPONSOR A CHILD WIDGET
  //------------------------------------------
  .pane-sponsorship-catalogue-panel-pane-homepage-child {
    margin-bottom: 2rem;
    text-align: center;
    position: relative;

    @include breakpoint(lg) {
      @include span(3 of 12 last);
      margin-bottom: 0;
    }
    img {
      border: 10px solid $white;
    }
    .views-field-sku {
      display: none;
    }
    h2 {
      font-size: 1.6875rem;
      color: $wv-orange;
      margin-bottom: 0;
      font-weight: bolder;
      line-height: 25px;
    }
    .views-field.views-field-field-first-name {
      h2 {
        font-size: 16px;
        margin: 0;
      }
    }
    .views-field.views-field-add-to-cart-form {
      @include breakpoint(lg) {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        top: 392px;
      }

      >span {
        text-align: -webkit-center;
      }

      .homepage-child-guest {
        width: 160px;
      }
    }
    .views-field-colorbox-1 a:before {
      @include icon(play-circle-o);
      margin-right: 0.5rem;
    }
    input.form-submit {
      font-size: 0.875rem;
    }
    .item-list {
      ul {
        margin: 0;
      }
      li {
        list-style: none;
        position: absolute;
        top: 7rem;
        &.pager-previous {
          left: 1rem;
          @include breakpoint(sm) {
            left: 0rem;
          }
          @include breakpoint(md) {
            left: -0.5rem;
          }
        }
        &.pager-next {
          right: 1rem;
          @include breakpoint(sm) {
            right: 0;
          }
          @include breakpoint(md) {
            right: -0.5rem;
          }
        }
        a {
          color: $orange;
          &:hover {
            color: $green;
          }
        }
      }
    }
    .pager-current {
      display: none;
    }
    .pager-previous a:after {
      @include icon(angle-left);
      font-size: 2rem;
    }
    .pager-next a:after {
      @include icon(angle-right);
      font-size: 2rem;
    }
    .view-footer {
      font-size: 0.875rem;
    }
  }

  // SUPPORT A GLOBAL CAUSE
  //------------------------------------------
  .pane-global-cause-panel-pane-1 {
    @include break;
    background: $white;
    padding: 2rem 0;
    .pane-title {
      text-align: center;
    }
    .pane-content {
      @include container;
    }
    .view-global-cause {
      margin: 0 auto;
      @extend %clearfix;

      .views-row {
        float: left;
        text-align: center;
        width: 50%;
        height: auto;
        min-height: 180px;
        margin-bottom: 1rem;

        @include breakpoint(xm) {
          width: 33%;
        }

        @include breakpoint(sm) {
          width: 24%;
        }

        @include breakpoint(md) {
          width: 14%;
        }
      }
    }
  }

  // WHERE HELP IS NEEDED
  //------------------------------------------
  .pane-where-help-is-most-needed-panel-pane-1 {
    background: $light-grey;
    padding: 2rem 0;

    .pane-title {
      text-align: center;
    }
    .pane-content {
      @include container;
    }
    .view-where-help-is-most-needed {
      @include breakpoint(xm) {
        width: 100%;
      }
      margin: 0 auto;
      .owl-item {
        background: $white;
        overflow: hidden;
        @include breakpoint(xm) {
          margin: 0 0.5rem 0 0;
        }
        @include breakpoint(md) {
          min-height: 350px;
        }

        img {
          width: 100%;
          height: inherit;
        }
        .views-field-sku {
          display: none;
        }
        .views-field-title,
        .views-field-view-node {
          margin: 1rem 0.875rem 1.5rem 0.875rem;
        }
        .views-field-title {
          font-size: $h3-font-size;
          line-height: 130%;
          a {
            color: $black;
            &:hover {
              color: $orange;
            }
          }
        }
        .views-field-view-node {
          a {
            @extend .cta-transparent;
          }
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  // GET INVOLVED LINKS
  //------------------------------------

  .front .panel-row-4 {
    padding: 2rem 0;
    .pane-title {
      text-align: center;
    }
  }

  // TESTIMONIALS
  .pane-testimonials-panel-pane-testimonials {
    .view-testimonials {
      .views-row {
        color: $white;
        text-align: center;
        .views-field-field-testimonial-image {
          @include breakpoint(xm) {
            @include pre(1 of 12);
            @include span(3 of 12);
          }
          @include breakpoint(sm) {
            text-align: right;
          }
          img {
            border-radius: 100px;
            height: inherit;
          }
        }
        .views-field-field-testimonial-quote {
          font-size: $h3-font-size;
          font-weight: normal;
          line-height: 130%;
          margin-bottom: 1rem;
          h3:before {
            @include icon(quote-left);
            margin-right: 0.5rem;
          }
          h3:after {
            @include icon(quote-right);
          }
        }
        .views-field-field-testimonial-by {
          @include breakpoint(xm) {
            @include span(8 of 12);
            margin-right: 0;
            text-align: left;
          }
          @include breakpoint(sm) {
            @include span(6 of 12);
          }
          .cta-green {
            display: inline-block;
          }
        }
      }
    }
  }
}

// NAVIGATION
// --------------------------------------------------
#navigation {
  color: $black;
  background: $orange;
  position: sticky;
  top: 0;
  z-index: 100;

  > .container {
    position: relative;
  }
}

.region-navigation {
  position: relative;
  &-right {
    position: absolute;
    right: 0;
    top: 22px;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    z-index: 501;

    @include breakpoint(md) {
      top: 29px;
    }
  }
}

#block-responsive-dropdown-menus-main-menu {
  flex-grow: 2;
}

#main-menu {
  @include breakpoint(md) {
    display: flex;
  }
  padding: 0;
  margin: 0;
  li {
    font-size: 1rem;
    display: inline-block;
    padding: 0;
    box-sizing: border-box;
    @include breakpoint(md) {
      &.menu-item.menu-item-primary {
        height: 58px;
        &> a {
          padding: 19px 0.5rem;
        }
        &:has(a.active) {
          border-bottom: 5px solid $white;
        }
      }
    }
    @include breakpoint(lg) {
      font-size: 1.125rem;
    }
  }
  li.menu-item.menu-item-secondary {
    a {
      &:hover {
        color: $orange;
        background: $white;
        border-radius: 5px 5px 0 0;
        font-weight: bold;
      }
    }
  }
  a {
    padding: 0.5rem;
    display: inline-block;
    text-decoration: none;
    color: $white;
    @include breakpoint(md) {
      padding: 0.5rem 0.7rem;
    }
    @include breakpoint(lg) {
      padding: 0.5rem 1rem;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    width: 230px;
    background: #efefef;
    li {
      font-size: 1rem;
      margin: 0;
      padding: 0;
      display: block;
      a {
        color: $black;
        border-bottom: 1px solid $mid-grey;
        display: block;
        border-radius: 0 !important;
      }
    }
  }
}

.sponsor-donate-block {
  &-container {
    display: flex;
    column-gap: 0.5rem;
  }

  display: inline-block;

  a {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.25rem 0.75rem;
    font-size: 0.75rem;
    font-weight: bold;
    color: $wv-orange;
    text-transform: uppercase;
    text-decoration: none;
    background: $white;
    border: 1px solid $wv-orange;
    border-radius: 5px;

    @include breakpoint(md) {
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem;
    }

    &:hover {
      color: white;
      background: $wv-blue;
    }
  }
}

// MOBILE MENU
// --------------------------------------------------
.mean-container {
  a.meanmenu-reveal {
    padding: 0;
    top: 22px;
    transform: translate(0, -50%);
  }
  .mean-bar {
    background: 0;
    padding: 0;
    z-index: 500 !important;
  }
  .menu-toggle {
    display: none;
  }
  .mean-nav {
    background: 0;
    ul li a {
      text-transform: none;
      width: 100%;
      padding: 16px 0 16px 0;
      font-weight: bold;
      &.mean-expand {
        border-bottom: 0;
        &:hover {
          background: rgba(255,255,255,.5);
        }
      }
    }
    li:last-child {
      margin-bottom: 1rem;
    }
    li li:last-child {
      margin-bottom: 0;
    }
    ul li li a {
      padding-left: 1rem;
      font-weight: normal;
      text-transform: inherit;
    }
  }
}

// BREADCRUMBS
// --------------------------------------------------

.breadcrumb {
  margin-top: 1rem;
  font-size: 0.75rem;
  a {
    color: #777777;
    &.active {
      color: $black;
      text-decoration: none;
    }
  }
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
       display: inline-block;
       margin-right: 0.5rem;
    }
  }
}

// SPONSORS ONLY MENU LINKS
// --------------------------------------------------
.sponsors-only {
  background: $orange;
  color: #fff !important;
  text-align: center;
  border-radius: 5px 5px 0 0;
  z-index: 500;
}

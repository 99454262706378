// Breakpoint mixin.
// Usage: @include breakpoint(sm) { ... }
// -----------------------------------------------------------------------------
@mixin breakpoint($class) {
  @if $class == xxs {
    @media (max-width: $screen-xxs-max) {
      @content;
    }
  }
  @else if $class == xs {
    @media only screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
      @content;
    }
  }
  @else if $class == xm {
    @media (min-width: $screen-xm) {
      @content;
    }
  }
  @else if $class == sm {
    @media (min-width: $screen-sm) {
      @content;
    }
  }
  @else if $class == md {
    @media (min-width: $screen-md) {
      @content;
    }
  }
  @else if $class == lg {
    @media (min-width: $screen-lg) {
      @content;
    }
  }
  @else {
    @warn "Breakpoint mixin supports: xxs, xs, xm, sm, md, lg";
  }
}

// Custom Mixins
// -----------------------------------------------------------------------------

@mixin debug($size, $color) {
  border: $size solid $color;
}

// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    clip: rect(1px 1px 1px 1px); // IE6 and IE7 use the wrong syntax.
  }
  clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

// Makes an element visually hidden by default, but visible when focused.
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

// Custom mixin for fontawesome icons
// Usage: @include icon(angle-right);
@mixin icon($icon) {
    @include fa-icon;
    @extend .fa;
    @extend .fa-#{$icon}:before;
    margin-left: 5px;
}


// Repeatable extends
// -----------------------------------------------------------------------------

.centralize {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.hide {
  display: none;
}

%cta {
  border-radius: 8px;
  outline: 0;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.375;
  padding: 10px 20px;
  text-transform: capitalize;
  &:hover {
    text-decoration: none;
  }
  display: inline-block;
}

.cta-orange {
  @extend %cta;
  background: $wv-orange;
  color: $white;
  &:hover {
    background: $wv-blue;
  }
}

.cta-green {
  @extend %cta;
  background: $wv-green;
  color: $white;
}

.cta-outline--grey {
  @extend %cta;
  background: $white;
  color: $wv-grey;
  border: 1px solid $wv-grey;
  &:hover {
    color: $wv-orange;
    border-color: $wv-orange;
  }
}

.cta-white {
  @extend %cta;
  background: $white;
  color: $wv-orange;
  &:hover {
    background-color: $wv-orange;
    color: $white;
  }
}

.cta-white--green {
  @extend .cta-white;
  &:hover {
    background-color: $wv-green;
  }
}

.cta-white--blue {
  @extend .cta-white;
  &:hover {
    background-color: $wv-blue;
  }
}

.cta-transparent {
  @extend %cta;
  background: none;
  border: 1px solid $wv-orange;
  color: $wv-orange;
  &:after {
    @include icon(angle-right);
  }
  &:hover {
    background: $wv-orange;
    color: $white;
  }
}

.cta-transparent--plain {
  @extend .cta-transparent;
  &:after {
    content: "";
    display: none;
  }
}

.cta-reverse {
  @extend %cta;
  background: none;
  border: 1px solid $white;
  color: $white;
  &:after {
    @include icon(angle-right);
  }
  &:hover {
    background: $white;
    color: $wv-orange;
  }
}

.cta-reverse--plain {
  @extend .cta-reverse;
  &:after {
    content: "";
    display: none;
  }
}

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

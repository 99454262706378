// GIFT BANNER
//-----------------------------------------
.section-gift-catalogue {
  .pane-custom.pane-1 {
    margin: 0 0 1rem 0;
    p {
      margin: 0;
    }
    img {
      width: 100% !important;
      height: inherit !important;
    }
  }
  .panel-col-last {
    .views-exposed-widget {
      padding: 0;
      float: none;
    }
    .views-widget .option {
      color: $secondary-font-color;
      font-weight: normal;
    }
    .form-item {
      padding: 0 0 1rem 0;
    }
    .form-checkboxes .form-item {
      padding: 0;
    }
    .form-submit {
      @extend .cta-transparent;
      margin-top: 0;
    }
  }
}

// EXPOSED FILTER SEARCH WIDGET
//-----------------------------------------
.views-widget-filter-field_gift_country_tid label:first-child,
.views-widget-filter-field_gift_theme_tid label:first-child,
.views-widget-filter-field_price_tid label:first-child {
  font-size: $h3-font-size;
  border-bottom: 1px solid #efefef;
  margin-bottom: 0.5rem;
  padding-bottom: 0.3rem;
  display: block;
}

.views-widget-filter-field_gift_theme_tid {
  margin-bottom: 1rem;
  .form-item {
    font-size: 0.95rem;
    padding: 0 !important;
  }
  .active {
    font-weight: bold;
    color: $dark-grey;
  }
}

// GIFT GRID LISTING
//-----------------------------------------
.view-gift-catalogue {
  .view-header {
    border-bottom: 1px solid $mid-grey;
    margin-bottom: 1rem;
    padding-bottom: 0.2rem;
    font-size: $h5-font-size;
  }
  .views-row {
    border: 1px solid $mid-grey;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    img {
      width: 100%;
      height: inherit;
    }
  }
  .views-field-title-field {
    flex-basis: 100%;
    margin: 0.5rem 1rem;
    font-weight: bold;
    font-size: $h3-font-size;
    a {
      color: $black;
      &:hover {
        color: $orange;
      }
    }
  }
  .views-field-commerce-price {
    margin: 1rem 1rem 0 1rem;
    font-size: $h4-font-size;
  }
  .views-field-add-to-cart-form {
    flex-shrink: 1;
    margin: 1rem 1rem 0 1rem;
  }
  .form-submit {
    @extend .cta-transparent;
    margin-top: 5px;
  }
  .item-list {
    clear: both;
    margin-top: 30px;
  }
  .form-item-quantity {
    margin: 0.5rem 0;
  }
  .form-item-quantity input {
    text-align: center;
  }

  @include breakpoint(xm) {
    .view-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;
    }
  }

  @include breakpoint(md) {
    .view-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1.5rem;
    }
  }
}

// GIFT NODE DETAILS
//-----------------------------------------
.node-gift {
  .group-left {
    width: 100%;
    margin-bottom: 1rem;
    @include breakpoint(xm) {
      @include span(6 of 12);
    }
  img {
    width: 100%;
    height: inherit !important;
    }
  }
  .group-right {
    width: 100%;
    @include breakpoint(xm) {
      @include span(6 of 12 last);
    }
  }
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid $mid-grey;

  .field-name-field-caption {
    font-style: italics;
    font-size: $h3-font-size;
  }
  .field-name-commerce-price {
    font-size: $h3-font-size;
    margin: 1rem 0 0;
  }
  .field-name-field-product {
    margin: 1rem 0 0;
    input {
      margin: 0 0 1rem;
      text-align: center;
    }
    input.form-submit {
      margin-bottom: 0;
    }
  }
}

// GIFT CATALOGUE PAGE
//-----------------------------------------
.mobile-filter {
  transition: 0.3s ease-in-out;
}

.page-gift-catalogue {
  #page {
    position: relative;

    // Overlay when filter is opened.
    &.mobile-filter-open::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: black;
      opacity: 0.3;
      z-index: 998;

      @include breakpoint(xm) {
        display: none;
      }
    }
  }

  // Disable overflow when filter is opened in mobile.
  &.mobile-filter-body-overflow {
    overflow: hidden;

    &::-webkit-scrollbar {
      display: block;
    }
  }

  #content {
    .panel-display {
      .panel-pane {
        // @TODO: This should ideally be selecting the 2nd col
        // Fix exposed filters to the right of the screen.
        &.gift-catalogue-exposed-filter-mobile {
          box-sizing: border-box;
          position: fixed;
          top: 0;
          right: -70vw;
          background: white;
          padding: 10px;
          z-index: 1000;
          height: 100vh;
          width: 70vw;

          @include breakpoint(xm) {
            position: initial;
            top: unset;
            right: unset;
            background: unset;
            padding: unset;
            z-index: unset;
            height: auto;
            width: auto;
          }

          // Mobile view exposed filter tab on the side.
          &::before {
            border-radius: 5px 5px 0 0;
            outline: 0;
            border: none;
            font-size: 0.875rem;
            padding: 10px 20px;
            text-transform: uppercase;
            background: $orange;
            color: white;

            content: 'Gift category';
            position: absolute;
            top: 50%;
            left: -42px;
            display: block;

            cursor: pointer;
            height: 42px;
            width: max-content;
            box-sizing: border-box;
            transform: translate(calc(-50% + 21px), -50%) rotate(270deg);
            z-index: 999;

            @include breakpoint(xm) {
              display: none;
            }
          }

          &.mobile-filter-open {
            right: 0;
          }

          #views-exposed-form-gift-catalogue-panel-pane-gift {
            margin-bottom: 20vh;

            .form-item {
              line-height: 28px;

              @include breakpoint(xm) {
                line-height: 22px;
              }

              input {
                width: 100%;

                @include breakpoint(xm) {
                  width: auto;
                }
              }
            }
          }

          > .pane-content {
            height: 100vh;
            overflow: scroll;

            @include breakpoint(xm) {
              height: auto;
              overflow: unset;
            }

            &::-webkit-scrollbar {
              display: none;
            }
          }

          // Close button added to form programmatically.
          .mobile-filter-close-button {
            position: absolute;
            top: 10px;
            right: 10px;

            @include breakpoint(xm) {
              display: none;
            }
          }
        }
      }
    }
  }
}

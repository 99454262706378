// CHECKOUT SECTION
// --------------------------------------------------

.section-checkout {
  fieldset {
    margin: 0 0 1.5rem 0;
    padding: 0;
    border: 0;

    legend {
      font-size: $h3-font-size;
      border-bottom: 1px solid $mid-grey;
      padding-bottom: 5px;
      margin-bottom: 1rem;
      width: 100%;
      color: $secondary-font-color;
    }
  }
  #commerce-checkout-form-checkout {
    .field-name-field-order-referrer-details {
      font-weight: bold;
    }
    .field-name-field-i-agree-to-the-terms-condi {
      display: inline-block;
      .form-item {
        &.form-type-checkbox {
          margin-top: 0;
        }
      }
      label {
        display: none;
      }
    }
    .field-name-field-terms-conditions {
      display: inline-block;
      margin-left: 0.7rem;
      vertical-align: top;
    }
  }
  .form-item {
    margin: 0.375rem 0;
    /* includes CSHS element, which has external JS styling the element */
    input,
    select.simpler-select {
      @include breakpoint(lg) {
        width: 400px;
      }
    }

    > label {
      display: inline-block;
      width: 100%;
      @include breakpoint(lg) {
        width: 25%;
        max-width: 95%;
      }
    }
    > label {
      margin-bottom: 0.5rem;

      @include breakpoint(lg) {
        min-width: 20%;
        margin-right: 0.5rem;
      }
    }
    .description {
      color: $secondary-font-color;
      font-style: italic;
    }

    &.form-type-checkbox, &.form-type-radio {
      display: flex;
      align-items: center;
      > * {
        width: auto;
      }
      label {
        margin-left: 0.5rem;
        margin-bottom: 0;
      }
    }

    &.form-type-cshs {
      .select-wrapper {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .make_a_card {
    background: $mid-grey;
    img {
      width: 100px !important;
    }
  }

  #edit-commerce-fieldgroup-pane-group-contact-information {
    margin-top: 40px;
    margin-bottom: 40px;
    color: $dark-grey;
  }

  *:disabled {
    color: $disabled-font-color;
  }

  .block-wvsg-shop td.line-item-amount {
    text-align: center;
  }

  #colorbox {
    @media (max-width: $screen-sm) {
      position: fixed !important;
      top: unset !important;
      left: 0 !important;
      bottom: 0;
      height: 80% !important;
      width: 100% !important;
    }
    #cboxWrapper {
      @media (max-width: $screen-sm) {
        height: 100% !important;
        width: 100% !important;

        div:has(#cboxContent) {
          height: 100%;
        }
      }
      #cboxContent {
        @media (max-width: $screen-sm) {
          height: calc(100% - 30px) !important;
          width: calc(100% - 30px) !important;
        }
        #cboxLoadedContent {
          @media (max-width: $screen-sm) {
            height: 100% !important;
            width: unset !important;
          }
        }
        #cboxClose {
          top: 0;
        }
      }
    }
  }
}

ol.inline.commerce-checkout-progress {
  box-sizing: border-box;
  list-style: none;
  margin: 0 0 1rem;
  padding: 0 0 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @include breakpoint(md) {
    padding: 0 0 3rem;
  }

  &::after {
    content: '' !important;
    display: none !important;
  }

  li {
    float: none;
    width: 100%;
    margin: 0;
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;
    text-align: center;
    color: $wv-dark-grey;
    font-size: 0.75rem;
    position: relative;

    @include breakpoint(md) {
      font-size: 0.875rem;
    }

    &::before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      bottom: -1.5rem;
      left: 50%;
      transform: translate(-50%, 0);
      color: $white;
      background: $wv-dark-grey;
      border-radius: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 5;

      @include breakpoint(md) {
        width: 2.5rem;
        height: 2.5rem;
        bottom: -2.5rem;
      }
    }

    &:not(.complete)::after {
      content: '';
      height: 7px;
      width: 100%;
      transform: translate(50%, 50%);
      background: $wv-dark_grey;
      position: absolute;
      bottom: -0.75rem;
      left: 0;

      @include breakpoint(md) {
        height: 13px;
        bottom: -1.25rem;
      }
    }

    &.checkout::before {
      content: '1';
    }
    &.payment::before {
      content: '2';
    }
    &.complete::before {
      content: '3';
    }

    &.active, &.visited {
      color: $wv-blue;
      font-weight: normal;
      &::before {
        color: $white;
        background: $wv-blue;
      }
    }

    &.visited::before, &.complete.active::before {
      content: '✓';
      font-size: 1rem;
      @include breakpoint(md) {
        font-size: 1.5rem;
      }
    }

    &.visited::after {
      background: $wv-blue;
    }
  }
}

fieldset.commerce_payment {
  .form-radios {
    margin-bottom: 1rem;
  }
}

fieldset.checkout-buttons {
  border: 0;
  padding: 0;

  .button-operator {
    margin: 0 0.5rem;
  }
}

//Cart Summary Table in Review Order Page
//----------------------------------------
.view-commerce-cart-summary {
  .views-table {
    width: 100%;
  }
  th {
  background: $orange;
  text-align: left;
  padding: 0.3rem;
  font-weight: bold;
  }
}

td.views-field-quantity,
th.views-field-quantity {
  text-align: center;
}

td.views-field-commerce-total,
th.views-field-commerce-total {
  text-align: right;
}

  // WITH ARROW
  // .item-list {
  //   ol {
  //     list-style: none;
  //     padding-left: 15px;
  //     li {
  //       background: $light-grey;
  //       height: 40px;
  //       line-height: 40px;
  //       text-transform: uppercase;
  //       color: $disabled-font-color;
  //       font-size: $h3-font-size;
  //       text-align: center;
  //       position: relative;
  //       //margin-left: 5px + 2 * $arrow_size;
  //       &:after {
  //         position: absolute;
  //         top: 50%;
  //         left: 100%;
  //         content: " ";
  //         height: 0;
  //         width: 0;
  //         pointer-events: none;
  //         border: solid transparent;
  //         border-left-color: $light-grey;
  //         border-width: $arrow_size;
  //         margin-top: -$arrow_size;
  //       }
  //       @extend .col-sm-3;
  //     }
  //     li.active {
  //       color: #fff;
  //       background: $green;
  //       z-index: 99;
  //       &:after {
  //         border-left-color: $green;
  //       }
  //     }
  //     @for $i from 1 through $max_li {
  //       li.step#{$i} {
  //         z-index: $max_li - $i;
  //       }
  //     }
  //   }
  // }
  

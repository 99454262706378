#exit-warning-modal {
  left: 50%;
  margin: -20vh 0 0 Max(-300px, -40%);
  opacity: 0;
  position: absolute;
  top: -50%;
  visibility: hidden;
  width: Min(600px, 80%);
  box-shadow: 0 3px 7px rgba(0,0,0,.25);
  box-sizing: border-box;
  transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  z-index: 503;

  &:target {
    opacity: 1;
    top: 50%;
    visibility: visible;
  }

  .modal-content {
    position: relative;
    z-index: 510;
  }

  .copy {
    padding: 1.5em;
    color: #363636;
    background: white;
    border-radius: 3px;
    border: 1px solid $orange;

    a {
      display: inline-block;
      padding: 10px 20px;
      margin-right: 20px;

      &#modal-continue {
        padding-left: 0;
      }
    }
  }

  .overlay {
    background-color: #000;
    background: rgba(0,0,0,.8);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 505;
  }

}

// CHOSEN DETAIL PAGE
//------------------------------------------
.page-chosen, .page-chosen-chinese {
  .webform-client-form {
    input:not(.form-submit) {
      height: 40px;
      width: 100%;
      background-color: #f5f8fa;
    }
  }
}

.node-page {
  .rtecenter {
    img {
      display: initial;
    }
  }
  img {
    display: block;
    float: none;
    width: 100%;
    height: inherit;
    padding: 0;
    margin: 0 0 1rem 0;
    @include breakpoint(md) {
      width: inherit;
      max-width: 100%;
    }
  }
}

.view-faqs {
  .ui-widget {
    font-family: $lato;
  }
  .views-row {
    margin-bottom: 0.5rem;
  }
  .views-row-last {
    margin-bottom: 1.5rem;
  }
  .views-field-title {
    color: #fff;
  }
  .ui-accordion {
    .ui-accordion-header {
      font-size: 1rem;
      border: 0;
      background: #F1EFEF;
      padding: 0.5rem 1.8rem 0.5rem 1rem;
      a {
        color: $orange;
      }
      &:hover {
        background: #E0E0E0;
      }
    }
   .ui-accordion-header-active {
    border-color: $mid-grey;
    border-bottom: 0;
    background-image: none;
    background: $orange;
      a {
        color: $white;
      }
      &:hover {
        background: $orange;
      }
      .ui-accordion-header-icon {
        background-position: left bottom;
      }
    }
    .ui-accordion-header-icon {
      //display: none;
      left: inherit;
      right: .3rem;
      background-image: url('../images/ui-accordion-icons.png');
      background-position: left top;
    }
    .ui-widget-content {
      padding: 1em 1em;
      font-size: 1rem;
      border-color: $mid-grey
    }
    .ui-accordion-content {
      a {
        color: $orange !important;
        &:hover {
          color: $orange !important;
          text-decoration: underline;
        }
      }
    }
  }
}

// Advisory council members listing
.view-advisory-council {
  .node-teaser.ds-2col {
    .group-left {
      @include span(4 of 12);
      clear: none;
      @include breakpoint(xm) {
        img {
          max-width: 127px;
          height: inherit;
          margin-right: 1.5rem;
        }
      }
      @include breakpoint(sm) {
        width: 140px;
      }
    }
    .group-right {
      clear: none;
      @include breakpoint(xs) {
        @include span(full);
      }
      @include breakpoint(xm) {
        @include span(8 of 12 last);
      }
      @include breakpoint(sm) {
        @include span(8 of 12);
      }
      h2 {
        margin-bottom: 0;
      }
      .field-name-field-designation {
        margin-bottom: 0.5rem;
        font-weight: bold;
        color: $dark-grey;
      }
    }
  }
}

// Colors
// --------------------------------------------------

$green:           #99cc00;
$orange:          #ff6600;
$white:           #ffffff;
$black:           #000000;
$red:             #ff0000;
$blue:            #00accb;

$light-orange:    #ffe1cc;
$dark-grey:       #424242;
$mid-grey:        #c4c4c4;
$light-grey:      #f2f2f2;

$primary-font-color:          rgba(0, 0, 0, 0.87);
$secondary-font-color:        rgba(0, 0, 0, 0.54);
$disabled-font-color:         rgba(0, 0, 0, 0.38);

$secondary-font-color-dark:   rgba(0, 0, 0, 0.7);

$orange-btn-hover-color:      rgba(255, 102, 0, 0.9);
$green-btn-hover-color:       rgba(153, 204, 0, 0.85);

// World Vision Brand Colors
// --------------------------------------------------

/* Primary Colors */
$wv-orange:            #ff6b00;
$wv-orange-80:         #ff8833;
$wv-orange-60:         #ffa666;
$wv-orange-40:         #ffc399;
$wv-orange-20:         #ffe1cc;
$wv-charcoal:          #333333;
$wv-dark-grey:         #666666;
$wv-grey:              #999999;
$wv-light-grey:        #cccccc;
$wv-very-light-grey:   #f2f2f2;
$wv-field-grey:        #cac4bb;

/* Secondary Colors */
$wv-maroon:       #842E14;
$wv-yellow:       #fdd25f;
$wv-purple:       #9054a1;
$wv-teal:         #006661;
$wv-blue:         #00accb;
$wv-green:        #46bb95;


// BREAKPOINTS
// --------------------------------------------------

$screen-xxs-max:  399px;
$screen-xs-min:   400px;
$screen-xs:       480px;
$screen-xs-max:   479px;
$screen-xm:       600px;
$screen-sm:       768px;
$screen-md:       1024px;
$screen-lg:       1280px;

// CHECKOUT CART VARIABLES
// --------------------------------------------------

$line_height: 20px;
$padding: 9px;
$arrow_size: ($line_height + 2 * $padding)/2;
$max_li: 10;

:root {
  --wv-orange: #{$wv-orange};
  --wv-orange-80: #{$wv-orange-80};
  --wv-orange-60: #{$wv-orange-60};
  --wv-orange-40: #{$wv-orange-40};
  --wv-orange-20: #{$wv-orange-20};
  --wv-charcoal: #{$wv-charcoal};
  --wv-dark-grey: #{$wv-dark-grey};
  --wv-grey: #{$wv-grey};
  --wv-light-grey: #{$wv-light-grey};
  --wv-very-light-grey: #{$wv-very-light-grey};
  --wv-field-grey: #{$wv-field-grey};
  --wv-blue: #{$wv-blue};
}

.sponsorship-campaign {
  #main {
    padding-bottom: 0;
  }
  #content {
    @include full;
    max-width: inherit;
  }
  .row-inner {
    @extend .container;
    overflow: hidden;
  }
  h1.page__title {
    @extend .container;
  }
}

// Success Stories from Blog
.sponsorship-campaign:not(.page-sponsor-children-what-is-child-sponsorship-2024) .panel-row-5 {
  background: $light-grey;
  padding: 1rem 0;
  @include breakpoint(xm) {
    padding: 2rem 0;
  }
  .view-blogs {
    clear: both;
    .view-content {
      @include breakpoint(sm) {
        display: flex;
      }
    }
    .views-row {
      flex: 1;
      width: 100%;
      background: #fff;
      margin: 0 0 1rem 0;

      @include breakpoint(xm) {
        @include span(4 of 12);
        &-last {
          @include span(4 of 12 last);
        }
      }

      img {
        width: 100%;
        height: inherit;
      }
      .views-field-term-node-tid,
      .views-field-view-node,
      .views-field-title {
        margin: 0.5rem 1.2rem 0.5rem 0.875rem;
      }
      .views-field-title {
        font-size: $h3-font-size;
        font-weight: 700;
        a {
          color: $primary-font-color;
          &:hover {
            color: $orange;
          }
        }
      }
      .views-field-term-node-tid {
        em {
          font-style: normal;
        }
        a {
          color: $secondary-font-color;
          &:hover {
            color: $orange;
          }
        }
      }
      .views-field-view-node {
        a {
          display: inline-block;
          @extend .cta-transparent;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

#slick-asnavfor-thumbnail {
  .slick__slider {
    overflow: hidden;

    &:before, &:after {
      content: '';
      background: white;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 30px;
      z-index: 50;
    }

    &:after {
      right: 0;
    }

    .slick-list {
      padding-top: 20px !important;
      .slick-track {
        .slick__slide {
          &[aria-hidden="true"] {
            
          }
          &:focus {
            outline: 0;
          }
          img {
            cursor: pointer;
            position: relative;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
            width: 175px;
            max-width:200px;
            &:focus {
              outline: 0;
            }
          }
          .slide__caption {
            .child-introgroup {
              text-align: center;
              p {
                padding-bottom: 20px;
                .date-display-interval {
                  font-weight: bold;
                }
              }
              .commerce-add-to-cart {
                margin: 10px 0;
              }
              .accordion {
                cursor: pointer;
                &:hover {
                  color: $orange;
                }
                .arrow:before {
                  content: "\25BC";
                }
                .up:before {
                  content: "\25b2";
                }
              }
            }
          }
        }
        .slick-current {
          box-shadow: none;
          transform: scale(1);
          &:before {
            content: none;
          }
        }
      }
    }
  }
  .slick__arrow {
    top: 225px;
    z-index: 100;

    /* Override carousel buttons so buttons will not be cut off on smaller screens */
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
  }
}
#slick-asnavfor {
  overflow: hidden;
  max-height: 0;
  transition: all 0.5s ease;

  &.show {
    max-height: 2000px;
    transition: all 1s ease;
  }

  .slick__slider {
    margin-top: 18px;
    position: relative;
    border-radius: 10px;
    border: 1px solid $orange;
    &:before,
    &:after {
      content: "\0020";
      display: block;
      position: absolute;
      top: -15px;
      left: 48%;
      z-index: 2;
      width: 0;
      height: 0;
      overflow: hidden;
      border: solid 20px transparent;
      border-top: 0;
      border-bottom-color :#FFF;
    }
    &:before {
      top: -20px;
      z-index: 1;
      border-bottom-color: $orange;
    }
    .slick-list {
      .slick-track {
        .slick__slide {
          &[aria-hidden="true"] {
            visibility: hidden;
          }
          .slide__content {
            padding: 30px 0 20px 0;
            .slide__media {
              display: none;
            }
            .slide__caption {
              position: unset;
              width: 100%;
              background: transparent;
              color: inherit;
              padding: 0;
              .slide__description {
                padding-top: 10px;
              }
              .child-infogroup {
                display: flex;
                @include breakpoint(sm) {
                  @include span(4 of 12);
                  display: block;
                }
              }
              .child-infogroup > div {
                padding: 1rem;
                text-align: center;
                border-right: 1px solid $mid-grey;
                .highlight {
                  font-weight: bold;
                }
                i {
                  display: block;
                  width: 30px;
                  height: 50px;
                  margin: 0 auto;
                  margin-bottom: 0.5rem;
                }
                &.child-name {
                  display: none;
                  @include breakpoint(sm) {
                    display: block;
                  }
                }
                &.child-birthday{
                  & i {
                    background: url("../images/ico_child-birthday.png") center center no-repeat;
                    background-size: 100%;
                  }
                  .date-display-single {
                    font-weight: bold;
                  }
                } 
                &.child-subject i {
                  background: url("../images/ico_child-subject.png") center center no-repeat;
                  background-size: 100%;

                }
                &.child-hobby {
                  border-right: 0;
                  @include breakpoint (sm){
                    border-right: 1px solid $mid-grey;
                  }
                  & i {
                    background: url("../images/ico_child-hobby.png") center center no-repeat;
                    background-size: 100%;
                  }
                } 
              }
              .node-adp {
                padding: 0 20px;
                @include breakpoint(sm) {
                  @include span(7 of 12);
                  padding: 0;
                }
                margin-top: 16px;
                h2 {
                  &:before {
                    content: "About ";
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.sponsorship-campaign {
  .panel-row-1 {
    h2.pane-title {
      margin: 0;
      text-align: center;
    }
    .pane-2 {
      .pane-content {
        text-align: center;
        .sponsor-cta {
          margin: 30px auto;
          text-align: center;
          display: inline-block;
          @extend .cta-orange;
          a{
            color: white;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .panel-row-2 {
    /* CSS for sponsor-children-all exposed filter */
    margin: 20px auto;
    .views-exposed-form {
      background: $orange;
      border-radius: 5px;
      text-align: center;
      padding: 1rem 0;
      .views-exposed-widgets {
        margin: 0 5%;
        @include breakpoint(md) {
          display: flex;
          justify-content: center;
        }
      }
      .views-exposed-widget {
        width: 100%;
        float: none;
        .form-select {
          width: 100%;
          padding: 8px;
          font-size: 0.9rem;
          appearance: none;
          color: $dark-grey;
          background: url("../images/ico-caret-down.png") #fff no-repeat 97% center;
          border-radius: 3px;
        }
        .date-padding {
          float: none;
        }
        .container-inline-date {
          .form-type-date-select {
            width: 100%;
          }
          .form-item {
            float: none;
          }
        }
        @include breakpoint(md) {
          float: left;
          width: 12%;
        }
        &.views-submit-button {
          @include breakpoint(md) {
            width: 15%;
          }
        }
        .form-submit {
          margin: 0;
          width: 100%;
          @extend .cta-green;
          @include breakpoint(sm) {
          }
        }
      }
      .views-widget-filter-field_child_adp_target_id {
        @include breakpoint(md) {
          width: 24%;
        }
      }
    }
  }
  .panel-row-3 {
  }
  .panel-row-4 {
    padding: 2rem 0;
  }
}

/* Styling for faux tab */
/* Sponsor Children All page and Longest Waiting/Orphaned Children pages */
.page-sponsor-children {
  #views-exposed-form-sponsorship-queue-sponsorship-campaign-waiting-new:not(.active),
  #views-exposed-form-sponsorship-queue-sponsorship-2024:not(.active) {
    display: none;
  }
  /* Style the buttons that are used to open the tab content */
  .faux-tab {
    text-align: center;
    
    /* Default CSS for tabs */
    .tabs {
      display: inline-flex;
      margin-bottom: 1px;
      cursor: default;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      a {
        background-color: $white;
        color: $orange;
        padding: 15px;
        border: 1px solid $orange;
        margin-bottom: -2px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 2px;

        &:first-child {
          border-top-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 10px;
        }

        &:hover {
          background-color: $light-orange;
          cursor: pointer;
        }

        &.active {
          background-color: $orange;
          color: $white;
        }
      }
    }

    /* Default CSS for tabcontents */
    .tabcontents {
      &.active {
        background-color: $orange;
        border-radius: 5px;
        min-height: 44px;

        display: block;
        padding: 1rem;

        & > div {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 5px;
          padding-top: 8px;
        }

        a.active {
          background-color: $wv-green;
          color: $white;
        }
      }

      &:not(.active) {
        display: none;
      }
    }
  }

  /* Banner */
  /* Container holding the image and the text */
  .sponsor-child-banner {
    background-size: cover;
    background-position: 50% 50%;
    height: 50vh;
    position: relative;
    color: white;
  }

  /* Banner content */
  .sponsor-child-banner-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5%;

    h2 {
      font-size: 5vh;
      font-weight: bolder;
      text-shadow: 1px 1px $black;
    }

    /* Content width should be 100% when displayed on smaller devices */
    p {
      margin-bottom: 40px;
      max-width: 100%;
      font-size: 16px;
      font-weight: bolder;
      &.sponsor-child-banner-text {
        text-shadow: 1px 1px $black;
      }
      &.sponsor-child-banner-buttons {
        display: inline;
        font-size: 16px;
        text-decoration: none;
        & > a:hover {
          text-decoration: none;
        }
      }

      /* Content width should be 50% when displayed on larger devices */
      @include breakpoint(sm) {
        max-width: 50%;
      }
    }
    .sponsor-child-banner-buttons-find-a-child,
    .sponsor-child-banner-buttons-learn-more {
      padding: 10px;
      margin-right: 10px;
      text-transform: none;
    }

    .sponsor-child-banner-buttons-learn-more {
      background-color: #ddd;
      color: $black;
      &:hover {
        background-color: $light-grey;
      }
    }
  }

  /* CSS for Sponsor a Child Page Content (Custom Block) */
  .sponsor-child-content {
    background-color: #e6f5f9;
    text-align: center;
    padding: 20px;
    margin: 40px 0;
    & > div {
      padding: 20px;
      border-bottom: solid 1px $mid-grey;
      &:last-child {
        border-bottom: 0;
      }
    }
    .sponsor-child-content-buttons {
      border: 1px solid $black;
      color: $black;
      font-size:16px;
      font-weight: bold;
      text-decoration: none;
      padding: 10px;
    }

    /* Instead of displaying inline, stack divs if screen size is small */
    @include breakpoint(sm) {
      display: flex;
      & > div {
        flex: 1;
        border-bottom: 0;
        border-right: solid 1px $mid-grey;
        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  /* CSS for faux-tab on Sponsor Children All page */
  .faux-tab {
    .tabs {
      .sponsor-children-all-tab1 {
        background-color: $orange;
        color: $white;
      }
    }
  }

  /* CSS for faux-tab on Child Sponsorship Longest Waiting/Orphans pages */
  &.page-sponsor-children-longest-waiting-children,
  &.page-sponsor-children-orphans,
  &.page-sponsor-children-special-needs-children,
  &.page-sponsor-children-conflict-affected-children {
    .faux-tab {
      .tabcontents {
        height: auto;
      }
      .tabs .sponsor-children-tab2 {
        background-color: $orange;
        color: $white;
      }
    }
  }
}

/* MLW sponsorship styling */

.sponsor-children__pair-for-me {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @include breakpoint(md) {
    padding-left: 2.875rem;
    padding-right: 2.875rem;
  }
  p {
    margin: 0;
  }
  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @include breakpoint(sm) {
      flex-direction: row;
      gap: 1.5rem;
    }
    h3 {
      margin: 0;
    }
  }
}
.sponsor-children__or {
  position: relative;
  margin-bottom: 0;
  padding: 1.5rem 0;
  &:after {
    content: '';
    background-color: $wv-dark-grey;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
  }
  .sponsor-children__or-text {
    display: inline-block;
    padding: 0 1rem;
    background-color: $wv-very-light-grey;
    position: relative;
    z-index: 10;
  }
}

.page-sponsor-children {
  .faux-tab-2024 {
    background-color: $wv-very-light-grey;
    .tabs {
      a {
        border: none;
        color: $white;
        text-transform: none;
        background-color: $wv-grey;
        padding: 0.625rem 1.2rem;
        letter-spacing: unset;
        display: inline-block;
        &:hover {
          background-color: $wv-grey;
        }
        &.active {
          background-color: $wv-blue;
          &:hover {
            background-color: $wv-blue;
          }
        }
      }
      h6 {
        margin: 0;
        &:first-child a {
          border-top-left-radius: 100px;
          border-bottom-left-radius: 100px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:last-child a {
          border-top-right-radius: 100px;
          border-bottom-right-radius: 100px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

#views-exposed-form-sponsorship-queue-sponsorship-2024, .faux-tab-2024 #sponsor-children-tab2-contents {
  * {
    box-sizing: border-box !important;
  }
  background-color: $wv-very-light-grey;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  padding: 1rem 1.5rem 1.5rem;
  @include breakpoint(md) {
    padding: 1.5rem 2.875rem 2.875rem;
  }
  .views-exposed-widget {
    margin: 0;
    padding: 0;
    float: none;
  }
  .views-submit-button {
    display: block;
    flex-basis: 100%;
    .form-submit {
      margin: 0;
    }
  }

  .views-exposed-widgets, .sponsorship-need-wrapper {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;

    > * {
      flex-grow: 1;
      text-align: center;
    }

    .form-select {
      width: 100%;
      padding: 0.65rem 0.5rem;
    }

    label.option {
      width: 100%;
      &:hover {
        cursor: pointer;
      }
    }

    input[type="radio"] {
      display: none;
      &:checked + label.option {
        color: $wv-orange;
        border-color: $wv-orange;
      }
    }

    &::after {
      content: '';
      display: none;
    }
  }
}

.sponsorship-listing {
  * {
    box-sizing: border-box !important;
  }
  .view-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    @include breakpoint(sm) {
      flex-direction: row;
    }

    .views-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      @include breakpoint(sm) {
        width: 50%;
      }
      @include breakpoint(md) {
        width: 33.3%;
      }

      .views-field-field-profile-picture {
        text-align: center;
        position: relative;
        padding-top: 1.25rem;
        z-index: 10;
        width: 100%;
        flex-grow: 0;
        > .field-content {
          display: inline-block;
        }
        img {
          width: 184px;
          height: 251px;
          object-fit: cover;
          border-radius: 0.4rem;
        }
      }

      .views-field-nothing {
        text-align: center;
        position: relative;
        z-index: 5;
        margin-top: -4.25rem;
        padding: 1rem 0.75rem 1.25rem;
        width: 100%;
        flex-grow: 1;
        > .field-content {
          display: inline-block;
          padding: 4.5rem 2rem 2rem;
          background: $wv-very-light-grey;
          border-radius: 0.75rem;
          width: 100%;
          height: 100%;
          max-width: 20rem;
          @include breakpoint(sm) {
            max-width: 24.8125rem;
          }
        }
      }

      animation: childProfileAnimation ease 3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  .empty-result {
    text-align: center;
    h3, p {
      margin-bottom: 0;
    }
    &__icon {
      img {
        width: 7.5rem;
        height: auto;
        @include breakpoint(sm) {
          width: 10.625rem;
        }
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    @include breakpoint(sm) {
      row-gap: 1.5625rem;
    }
  }
}

.sponsorship-listing, #cboxContent {
  .child-details {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;

    > *:not(:last-child) {
      flex-grow: 0;
      margin-bottom: 0.75rem;
    }

    &__name, &__greetings-trigger, &__sponsor-button {
      width: 100%;
    }

    &__group {
      margin-bottom: 0.25rem !important;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 1rem;

      > * {
        &:nth-child(2n+1) {
          justify-content: flex-end;
        }
        &:not(.filler) {
          margin-bottom: 0.5rem;
        }
      }
    }

    .child-details__filler {
      width: 100%;
      flex-grow: 100;
      margin-bottom: 0;
    }
  }

  .child-details__name {
    font-size: 1.5rem;
    line-height: 1.2;
    .child__first_name {
      color: $wv-blue;
    }
  }

  .child-details__greetings-trigger {
    text-transform: uppercase;
    a {
      text-decoration: underline;
    }
    &:not(:has(a)) {
      display: none;
    }
  }

  .child-details__favourite {
    text-transform: capitalize;
  }

  .child-details__gender, .child-details__country, .child-details__birthday, .child-details__favourite {
    display: inline-flex;
    align-items: center;

    &-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      content: '';
      margin-right: 0.25rem;
    }
  }

  .child-details__gender-icon {
    background: url('../images/gender.svg') no-repeat center center;
    background-size: contain;
  }
  .child-details__country-icon {
    background: url('../images/country.svg') no-repeat center center;
    background-size: contain;
  }
  .child-details__birthday-icon {
    background: url('../images/birthday.svg') no-repeat center center;
    background-size: contain;
  }
  .child-details__favourite-icon {
    background: url('../images/favourite.svg') no-repeat center center;
    background-size: contain;
  }

}

#cboxContent:has(.child-details) {
  min-height: content;
}

@keyframes childProfileAnimation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#sponsor-a-child:target {
  margin-top: -75px;
  padding-top: 75px;
}

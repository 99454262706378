.node-type-webform.section-cancel {
  form {
    .form-item {
      input.form-text {
        width: 100%;
      }

      &.webform-component-textarea {
        width: 100%;
      }
    }
  }
}
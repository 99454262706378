// FOOTER
// --------------------------------------------------
#footer {
  padding: 2rem 0;
  background-color: $dark-grey;
  color: $white;
  font-size: 0.8rem;
  
  .footer-content {
    @include breakpoint(md) {
      display: flex;
      gap: 3rem;
    }
  }

  .region-footer-left {
    flex-basis: 50%;
  }
  
  .region-footer-right {
    flex-basis: 50%;
    margin-top: 10px;

    @include breakpoint(md) {
      margin-top: 0;
    }
  }
  .region-footer-below {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid;

    @include breakpoint(xm) {
      justify-content: space-between;
    }
  }
  .block-title {
    font-size: $h3-font-size;
    margin: 0 0 1.25rem;
  }
}

.social-media {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    display: inline-block;
    margin-right: 0.5rem;
  }
  a {
    color: $white;
  }
  a:hover {
    color: $orange;
  }
}

.copyright {
  font-size: 0.75rem;
  color: #d8d8d8;
  line-height: 150%;
  margin: 0;
  text-align: left;
}

#panels-ipe-control-container {
  display: none !important;
}

.panels-ipe-button-container {
  display: none;
}

#sliding-popup {
  .popup-content {
    #popup-text {
      p { 
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
  .eu-cookie-compliance-default-button {
    font-weight: normal;
  }
}


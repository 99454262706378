// Import Zen Default Styles
// --------------------------------------------------

/**
 * Messages.
 */
.messages,
.messages--status,
.messages--warning,
.messages--error {
  position: relative;
  margin: 0 0 1.5em 0;
  padding: 10px 10px 10px 50px; /* LTR */
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAD6UlEQVR42s2WX0xbVRzH3YwmC4k+GF/0ZS/S267/bmnX9nL7bwstZlnbjTDYyoC5GCbB0ZW5pdJCe6swbLFA6bpWIGuRMWVjKGP+21QW3SZBSAjGh4XEaTZTH82Cm/3ztS2xs7mw4KLRk3xyzj33/H6fe5Pz7zEA/yr/vUDukj9FH6drqTaqT8EoPs/UV+nX6TD1BlUh9AqLHlmgPKLcRHmoCOWmElK/FOKTYpS8UwLJkASiUyLI3pKhlClN0g46qj+qL/pbArlbrlO1q25JeiSgR2iYJ8ywXLSg/qP6LNl2ro8+Q4MMkKCd9K2t3q3KdQnkXXIF5aISkgEJzONm1F2qW52pDJN1MI2bUBIuAdVOJWSMTPNQgX6/vkjVpvpREpag6oMqWCYta1IzbsHh9ga0RJtzY8URMdRO9U/KSuWmNQUqh2pY3CtG+fvlqJyofMAFNrZAE+7e/RWR4X4cD9tgOGsA2U2CdtMDqwqyMyIzQ5KKqAKmcyaYxkzYd3YvjGNGFtXRPRj58DT+LOemRrFnrBLyITmUDmUyO/NYgu2d26ukHVJo3tXAMGpAs+cQmh0NeClan30uwN7TgnQ6nRd4r3thOGOAJqYB2UVC79AfZAnKHGUxQa8A2tNaNLW/jKvXv8Dyb8s4yryKA4O10A3roIvpUB+swTdz1/LJZ27PQBvT5lBH1RD4BChzlQ2wBNtc22aE/ULQgzRCl4P5BPcT93GMOYz9wb2QhCRgAq35d8u/L2PXe7tADVGgBlcQ+AXQtmlvsP/gzbJZvp8PMkJCFBYh8m0knyiVSsHe0YIGZz1+/uVOvt8z7QGvnwf+ST5EIRHIUyR4fh50rbp5lsDcYR4ReAXgBrng9q/Qfa0bfy035r7Ot2dvz4IX4IEIEAXwvDzscOw4zxJUd1YfEXlE4Aa4BQHMlwzSSBeI7iXvoTxWDqKPYCFsFaKmr+YVliB0JfS89DVpiuhlB9k/tSOZTuYFvq98yI7L0/MAsVWcGp0bfW61hbahwltxSeARsIKyWKesSKQSWIwvYkvvllwfx88pgOvhwthu/AzAxlVX8vz385tLbaVxwpcLZtEw0QDjsBGctzksiE4CimZFfHp++oWHbnbuUfdB0komMgHsRN1r0MWBsEmYODF5onY92/UTwcvBxuzXcN1ccHycVSn2FaPYWwzCQUDWKIt7z3utAJ5c74Hz+OLSomynY+cVfiM/xW3JiDyZpB3FuZrj4oCwE+Ad4qWMjPHjpTtL0mzMoxyZz9yM39Q7Y85Ok930icqm+k59TL2wm9l90dZv8y/8sPAigGf/iUN/Q4anM2zOsdLe+L+4VfwBVVjDs2rTYx0AAAAASUVORK5CYII=');
  *background-image: url('../../images/message-24-ok.png');
  background-position: 8px 8px; /* LTR */
  background-repeat: no-repeat;
  border: 1px solid #be7;
}
.messages--warning {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACuElEQVRIiWP4//8/Ay0xSYqntTpnT252zqeJBf0Njhsykrz/pyd6/e9vcNpGVQv6q2wlm0qc/r0+IPD/3UG+/61l9v9mdrjIUc2C7hqHUzc3S///eZwBjO9tF/vfWe1wjioWTKixVm8otPn38wQT3IKfxxn/t5Va/utpsNSg2ILWcttrNzdJgQ3+dpQRjEHs+9tE/zeXWt+gyILOamuTqlxrsOtPLub+7+emBsSq/88v5wL7oqHQ9H9nmbkF2RbUF1rev7lJEuziuU3i/90ddcB4UZsoJC62ifyvK7R4QJYFrcUGrmUZ5v9hYb9hosh/bzcDMN42VRgeF9W5hv8bi/XdSbagKtfs2c1NEvCIPbaQ/7+/pwkYn17Ki0hR24T/l2eZPCfJgsZ83dCiNOP/yCnn7iau/8G+5mD8aBsHSoqqyNL9X5erHUm0BcVpRm9ubhZHMoTh/4eDzP/DA23+RwTZ/P96hAlF7t5Wof8FyfpvibKgNk8noyDZ4D9quofg1Bjr/1kJlhjiIF+Upmn/r83RzCJoQXaC3qcbm8SwGMLwvybP/H8jMGlik7u7VeB/Zqz2J7wWVGdr1uTG62J1PQgfWST1/+hiCaxyIF8UJqv9r8hQrcVpQVqkzrcbG0WwGvB2H/P/lnx5MAaxsam5vYn3f2KY+jesFpSlqfZnxWjidP2OGWL/g/0swBjExu4Lhv958Ur/i5KU+lEsCA1lYI4JUv95bZ0gTo2Pt3P+z0myBmMQG5e6mxu4/kf4Kf8EmQm3oCRNebKrvSawIGPBqRG9sMOp5hjjfwdrlf/58bKT4RaUpWvtcLZV/39iscD/H0AFP46jYwYiMeP/44u4/9tbKQODSXUH3II9G7v18hI0n8YGKv+IDVT6joxj/BVx4mgcOCde/SnITPRUJAHEGlTCEkQV19TAAN8FC67hZdFXAAAAAElFTkSuQmCC');
  *background-image: url('../../images/message-24-warning.png');
  border-color: #ed5;
}
.messages--error {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACpElEQVR42rWWTUgbQRiGQ0Tx4MWDeFM8eBA9iKAoggiCoCiiIiL4L3oQV1CJB0UEf6iRYFpK7UniTw9VSqL2kvQsVDBpSZrtRo35czVNW3oprRf17exSl4yzu1ikAy9h59vvedhkMrMGAGoxknAk2w8MJ/WosXThiZkZt9jdLeglPjn5ATc3mhJNuNjbK0QbG3ExMICL/n6IfX0gcxB7ekDAELu6IHZ2IlJbi1hLS1BLogmPtbUhMTv7oMSamzUlqnByMxLT0/8STQkDj9TV4ZLj5OysrODl8jIu5Gs68dFR7JG6dWkJ0fFx+TpSX89IDMnwcHU1yKec12Yz3rlc4HkeLwjkXJpPip3U3+7vIx6P4ymph4eG5PlwTQ0lMdytlmBxMWKtrXLeT0zA5XTibvj9fjxfXETkb/3N/Dz2dneVuiTZtliU/rPSUsQ5ziuxZYG03IIlJdKKUPJjdRUAKMmzuTnskB/VYbdTtd9HR4g2NCi9Z2VliDY1BSnBaUEBzsrLqXyzWCiQ9HU5HA4afniIUFWV0hOqqMBpURErOM7NxWlhIZOvCwvA7S3Uxq+DA5AnZ3pO8vJYQSArC8c5Oeqx2Rj4udeLQH6+6v2B7GxW8DkjA0JmJpONwUHY7XZGIAgCzCYTeJUewmIFfqMRfEoKlQ2yJbza2oLWcLvdeDI2hk/3+iQWKzAYkJzNjg5srq9TwJ9OJ76YTNScx+ORJT66X1/grKyEbW2NgfPp6XKd/JMZySrHaQsSU1Oe+0/w3WpVgyu5HBlR6lc+H8gioevDwz6JrWwV5+3txyoSFk5DcOX1MnCyJ4Vwfb1zt1UY9SR8aioDpuppaVpwZbPTl+hHF04dOKzk8XBF8DgJC3/woU/W/EciOtELOWi8DDwp//215Q+p7kiKh2lQSAAAAABJRU5ErkJggg==');
  *background-image: url('../../images/message-24-error.png');
  border-color: #ed541d;
}
.messages__list {
  margin: 0;
}
.messages__item {
  list-style-image: none;
}

/* Core/module installation error messages. */
.messages--error p.error {
  color: #333;
}

/* System status report. */
.ok,
.messages--status {
  background-color: #f8fff0;
  color: #234600;
}
.warning,
.messages--warning {
  background-color: #fffce5;
  color: #840;
}
.error,
.messages--error {
  background-color: #fef5f1;
  color: #8c2e0b;
}

/**
 * Tabs.
 */

/* Basic positioning styles shared by primary and secondary tabs. */
.tabs-primary,
.tabs-secondary {
  overflow: hidden;
  *zoom: 1;
  background-image: -webkit-gradient(linear, 50% 100%, 50% 0%, color-stop(100%, #bbbbbb), color-stop(100%, transparent));
  //background-image: -webkit-linear-gradient(bottom, #bbbbbb 1px, transparent 1px);
  //background-image: -moz-linear-gradient(bottom, #bbbbbb 1px, transparent 1px);
  //background-image: -o-linear-gradient(bottom, #bbbbbb 1px, transparent 1px);
  background-image: linear-gradient(bottom, #bbbbbb 1px, transparent 1px);
  /* IE 9 and earlier don't understand gradients. */
  list-style: none;
  border-bottom: 1px solid #bbbbbb \0/ie;
  margin: 1.5em 0;
  padding: 0 2px;
  white-space: nowrap;
}
.tabs-primary__tab,
.tabs-primary__tab.is-active,
.tabs-secondary__tab,
.tabs-secondary__tab.is-active {
  float: left; /* LTR */
  margin: 0 3px;
}
a.tabs-primary__tab-link,
a.tabs-primary__tab-link.is-active,
a.tabs-secondary__tab-link,
a.tabs-secondary__tab-link.is-active {
  border: 1px solid #e9e9e9;
  border-right: 0;
  border-bottom: 0;
  display: block;
  line-height: 1.5em;
  text-decoration: none;
}

/* Primary tabs. */
.tabs-primary__tab,
.tabs-primary__tab.is-active {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  text-shadow: 1px 1px 0 white;
  border: 1px solid #bbbbbb;
  border-bottom-color: transparent;
  /* IE 9 and earlier don't understand gradients. */
  border-bottom: 0 \0/ie;
}
.is-active.tabs-primary__tab {
  border-bottom-color: white;
}
a.tabs-primary__tab-link,
a.tabs-primary__tab-link.is-active {
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  color: #333;
  background-color: #dedede;
  letter-spacing: 1px;
  padding: 0 1em;
  text-align: center;
}
a.tabs-primary__tab-link:hover,
a.tabs-primary__tab-link:focus {
  background-color: #e9e9e9;
  border-color: #f2f2f2;
}
a.tabs-primary__tab-link:active,
a.tabs-primary__tab-link.is-active {
  background-color: transparent;
  *zoom: 1;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFE9E9E9', endColorstr='#00E9E9E9');
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #e9e9e9), color-stop(100%, rgba(233, 233, 233, 0)));
  background-image: -webkit-linear-gradient(#e9e9e9, rgba(233, 233, 233, 0));
  background-image: -moz-linear-gradient(#e9e9e9, rgba(233, 233, 233, 0));
  background-image: -o-linear-gradient(#e9e9e9, rgba(233, 233, 233, 0));
  background-image: linear-gradient(#e9e9e9, rgba(233, 233, 233, 0));
  border-color: #fff;
}

/* Secondary tabs. */
.tabs-secondary {
  font-size: .9em;
  /* Collapse bottom margin of ul.primary. */
  margin-top: -1.5em;
}
.tabs-secondary__tab,
.tabs-secondary__tab.is-active {
  margin: 0.75em 3px;
}
a.tabs-secondary__tab-link,
a.tabs-secondary__tab-link.is-active {
  -webkit-border-radius: 0.75em;
  -moz-border-radius: 0.75em;
  -ms-border-radius: 0.75em;
  -o-border-radius: 0.75em;
  border-radius: 0.75em;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  text-shadow: 1px 1px 0 white;
  background-color: #f2f2f2;
  color: #666;
  padding: 0 .5em;
}
a.tabs-secondary__tab-link:hover,
a.tabs-secondary__tab-link:focus {
  background-color: #dedede;
  border-color: #999;
  color: #333;
}
a.tabs-secondary__tab-link:active,
a.tabs-secondary__tab-link.is-active {
  text-shadow: 1px 1px 0 #333333;
  background-color: #666;
  border-color: #000;
  color: #fff;
}
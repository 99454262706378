// REGION LAYOUTS
// --------------------------------------------------
#main {
  padding: 1rem 0 2rem;
  overflow: hidden;
}

.no-sidebars #content {
  @extend .container;
}

.one-sidebar {
  #content {
    @include breakpoint(sm) {
      @include span(8 of 12);
    }
    @include breakpoint(md) {
      @include span(9 of 12);
    }
  }
  #sidebar-second {
    @include breakpoint(sm) {
      @include span(4 of 12 last);
    }
    @include breakpoint(md) {
      @include span(3 of 12 last);
    }
    h2 {
      //font-size: $h2-font-size;
    }
    .block {
      margin-bottom: 1.5rem;
    }
  }
}

// POSTSCRIPT
// --------------------------------------------------
#postscript {
  background: $wv-very-light-grey;
  .region-postscript {
    padding: 2rem 0;
    h2 {
      text-align: center;
      color: $black;
    }
  }
}

// PANEL LAYOUT
// --------------------------------------------------

.panel-2col-bricks,
.panel-2col {
  .panel-col-first {
    @include full;
    @include breakpoint(xm) {
      @include span(9 of 12);
    }
    .inside {
      margin: 0;
    }
  }
  .panel-col-last {
    @include full;
    clear: none;
    border-top: 1px solid $mid-grey;
    padding-top: 1rem;
    margin-top: 1rem;
    @include breakpoint(xm) {
      @include span(3 of 12 last);
      border: 0;
      padding: 0;
      margin: 0;
    }
    .inside {
      margin: 0;
    }
  }
}

.panel-pane {
  h2.pane-title {
    margin-top: 0;
  }
}

.no-pane-title {
  h2.pane-title {
    display: none;
  }
}

// DS 2-col layout default
// --------------------------------------------------
.node-teaser.ds-2col {
  margin-bottom: 1rem;
  padding-bottom: 1rem;

  @extend %clearfix;

  .group-left {
    @include full;
    @include breakpoint(xm) {
      @include span(3 of 9);
      clear: none;
      img {
        width: 100%;
        height: inherit;
      }
    }
  }
  .group-right {
    @include full;
    @include breakpoint(xm) {
      @include span(6 of 9 last);
      clear: none;
    }
    h2 {
      margin-bottom: 0.5rem;
    }
  }
}


// CARD LANDING PAGES
// For the layout of card based landing pages or similar components
// --------------------------------------------------

.cards-listing,
.view-wvsg-landing {
  @extend %clearfix;

  .views-row {
    margin-bottom: 2rem;
    h3 {
      margin: 0.5rem 0;
    }
    img {
      width: 100%;
      height: inherit;
    }
    .field-name-field-card-blurb {
      margin: 0 0 1rem 0;
    }
    .field-name-field-card-link {
      margin-top: 1rem;
      a {
        @extend .cta-transparent;
      }
    }
    @include breakpoint(xm) {
      &:nth-child(2n+0) {
        margin-right: 0;
      }
      @include span(6 of 12);
    }

    @include breakpoint(sm) {
      &:nth-child(2n+0) {
        @include gutters;
      }

      &:nth-child(3n+0) {
        margin-right: 0;
      }

      &:nth-child(3n+1) {
        clear: both;
      }

      @include span(4 of 12);
    }
  }
  .views-row-last {
    margin-right: 0;
  }
}

// IMAGE RESPONSIVE COLUMNS FOR CLIENT TO EDIT
// --------------------------------------------------

.image-column-2, .image-column-3, .image-column-4, .image-column-5, .image-column-6, .image-column-7 {
  width: 100%;
  float: left;
  .image {
    width: 100%;
    height: auto;
    float: left;
    img {
      display: table-cell;
      vertical-align: middle;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }
  }
}

.image-column-2 {
  .image {
    @include breakpoint(sm) { 
      width: 50% !important;
    }
  }
}

.image-column-3 {
  .image {
    @include breakpoint(sm) { 
      width: 33.33%;
    }
  }
}

.image-column-4 {
  .image {
    @include breakpoint(sm) { 
      width: 50% !important;
    }
    @include breakpoint(md) {
      width: 25% !important;
    }
  }
}

.image-column-5 {
  .image {
    @include breakpoint(sm) { 
      width: 33.33% !important;
    }
    @include breakpoint(md) {
      width: 20% !important;
    }
  }
}

.image-column-6 {
  .image {
    @include breakpoint(sm) { 
      width: 33.33% !important;
    }
    @include breakpoint(md) {
      width: 16.66% !important;
    }
  }
}

.image-column-7 {
  .image {
    @include breakpoint(sm) {
      width: 33.33% !important;
    }
    @include breakpoint(md) {
      width: 14.28% !important;
    }
  }
}

// TAXONOMY TERM PAGES
// --------------------------------------------------
.page-taxonomy-term {
  .pane-term-description {
    margin-bottom: 1rem;
  }
}

/* CRO / full width panel row override */
.row-inner:has(.container--full) {
  overflow: unset !important;
}

.container--full {
  /* Undo the container width / margin */
  /* Refer to _base.scss for the container width */
  $inner-container-width: 90vw;
  $inner-container-width-sm: 95vw;
  $inner-container-width-md: 60rem;
  $inner-container-width-lg: map-get($susy, 'container');

  margin-left: calc((#{$inner-container-width} - 100vw) / 2);
  margin-right: calc((#{$inner-container-width} - 100vw) / 2);

  @include breakpoint(sm) {
    margin-left: calc((#{$inner-container-width-sm} - 100vw) / 2);
    margin-right: calc((#{$inner-container-width-sm} - 100vw) / 2);
  }

  @include breakpoint(md) {
    margin-left: calc((#{$inner-container-width-md} - 100vw) / 2);
    margin-right: calc((#{$inner-container-width-md} - 100vw) / 2);
  }

  @include breakpoint(lg) {
    margin-left: calc((#{$inner-container-width-lg} - 100vw) / 2);
    margin-right: calc((#{$inner-container-width-lg} - 100vw) / 2);
  }
}

.container__bg {
  &--very-light-grey {
    background: $wv-very-light-grey;
  }
  &--light-grey {
    background: $wv-light-grey;
  }
  &--grey {
    background: $wv-grey;
  }
  &--dark-grey {
    background: $wv-dark-grey;
  }
  &--charcoal {
    background: $wv-charcoal;
  }
  &--black {
    background: $black;
  }
  &--orange {
    background: $wv-orange;
  }
}

/* To update the values */
.container__top {
  &--sm {
    padding-top: 1rem;
    @include breakpoint(md) {
      padding-top: 1rem;
    }
  }
  &--md {
    padding-top: 1.5rem;
    @include breakpoint(md) {
      padding-top: 2.875rem;
    }
  }
  &--lg {
    padding-top: 3.875rem;
    @include breakpoint(md) {
      padding-top: 5.75rem;
    }
  }
}

.container__bottom {
  &--sm {
    padding-bottom: 1rem;
    @include breakpoint(md) {
      padding-bottom: 1rem;
    }
  }
  &--md {
    padding-bottom: 1.5rem;
    @include breakpoint(md) {
      padding-bottom: 2.875rem;
    }
  }
  &--lg {
    padding-bottom: 3.875rem;
    @include breakpoint(md) {
      padding-bottom: 5.75rem;
    }
  }
}

.container__lr {
  &--md {
    padding-left: 5%;
    padding-right: 5%;
    @include breakpoint(sm) {
      padding-left: 2.5%;
      padding-right: 2.5%;
    }
  }
}

.pane-content {
  margin: 0 auto;
  /* Default to lg */
  max-width: 75rem;

  .content-container--sm > & { max-width: $screen-sm; }
  .content-container--md > & { max-width: $screen-md; }
  .content-container--lg > & { max-width: 75rem; }
  .content-container--full > & { max-width: 100%; }

  > *:last-child {
    margin-bottom: 0;
  }
}

.header-banner {
  box-sizing: border-box;
  position: relative;
  min-height: 16.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include breakpoint(md) {
    min-height: 20rem;
  }

  .pane-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header-banner__content {
      position: relative;
      z-index: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1, h2, h3, h4, h5, p {
        text-shadow: 0px 0px 12px rgba(255,255,255,0.9);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .header-banner__bg-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 0;

    .header-banner__bg {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;

      &--contain {
        @include breakpoint(sm) {
          object-fit: contain;
        }
      }
    }
  }
}

.panel-pane.banner {
  @include breakpoint(md) {
    max-height: 525px;
  }

  .pane-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include breakpoint(sm) {
      flex-direction: row;
    }

    > * {
      flex-basis: 100%;
      width: 100%;
    }
    .banner__bg-container {
      display: flex;
      flex-basis: 56.25vw;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;
      @include breakpoint(sm) {
        flex-basis: 55%;
        width: auto;
        height: 525px;
      }
      .banner__bg {
        aspect-ratio: 16 / 9;
        object-fit: cover;
        width: 100%;
        height: 100%;
        &--contain {
          @include breakpoint(sm) {
            object-fit: contain;
            object-position: right;
            width: auto;
          }
        }
      }
    }
    .banner__content {
      padding: 2rem 5%;
      text-align: center;
      box-sizing: border-box;
      @include breakpoint(sm) {
        text-align: left;
        flex-basis: 45%;
        padding: 1.5rem 2.5% 1.5rem 2.75rem;
      }
    }
  }
}

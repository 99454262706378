// FORMS
// --------------------------------------------------

input, textarea {
  border-radius: 3px;
  outline: none !important;
  border: 1px solid $mid-grey;
  box-sizing: border-box;
  padding: 5px 0.5rem;
  background: $white;
}

.form-select {
  @extend input;
}

.fieldset-legend {
  color: $orange;
}

.form-required {
  color: $red;
}

.form-submit {
  cursor: pointer;
  @extend .cta-orange;
}

// WEBFORMS
// --------------------------------------------------
.webform-client-form {
  .form-item {
    margin-bottom: 1rem;
  }
  label {
    display: block;
    margin-bottom: 0.3rem;
    font-weight: bold;
    &.option {
      display: inline-block;
      font-weight: normal;
    }
  }
  input[type="text"], input[type="email"],
  .webform-component-textarea {
    width: 100%;
    @include breakpoint(sm) {
      width: 50%;
    }
  }
}

// SELECT OR OTHERS
// ---------------------------------------------------
.field-name-field-donation-amount {
  .select-or-other > div[class$="-other"] {
    label {
      font-weight: normal;
      font-size: $base-font-size;
    }
  }
}

.flexslider {
  .flex-direction-nav {
    a {
      padding-top: 15px;
    }
    a:before {
      font-size: 60px;
      color: $orange;
      font-family: "fontawesome";
      content: '\f104';
    }
    a.flex-next:before {
      content: '\f105';
    }
  }

  .container--full & {
    border: 0;

    img {
      max-height: 400px;
      object-fit: cover;
    }
  }
}

// TRIPS AND EVENTS LANDING PAGE
//-----------------------------------------
.view-event-registration {
  .views-row {
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $mid-grey;
  }
  .views-row-last {
    border: 0;
  }
  h2 {
    margin: 0 0 0.5rem 0;
    font-size: $h3-font-size;
  }
  .field-name-node-link {
    margin: 1rem 0;
    a {
      @extend .cta-orange;
      &:after {
        @include icon(angle-right);
      }
    }
  }
}

.node-event {
  .group-left {
    width: 100%;
    margin-bottom: 1rem;
    @include breakpoint(xm) {
      @include span(6 of 12);
    }
    img {
      width: 100%;
      height: inherit !important;
    }
  }
  .group-right {
    width: 100%;
    @include breakpoint(xm) {
      @include span(6 of 12 last);
    }
  }
  .field-name-field-image {
    img {
      width: 100%;
      margin-bottom: 1rem;
      height: inherit;
    }
  }
  .field-name-field-reg-status {
    .field-items {
      color: $orange;
    }
  }
}

// EVENTS DETAILS PAGE
//-----------------------------------------
.node-type-event {
  .panel-col-first {
    @include full;
    @include breakpoint(xm) {
      @include full;
    }
    @include breakpoint(sm) {
      @include span(9 of 12);
    }
  }
  .panel-col-last {
    @include breakpoint(xm) {
      @include full;
      border-top: 1px solid $dark-grey;
      padding-top: 1.5rem;
      margin-top: 1rem;
    }
    @include breakpoint(sm) {
      @include span(3 of 12 last);
      clear: none;
      border: none;
      padding: 0;
      margin: 0;
    }
  }
  .field-name-field-event-date {
    @include breakpoint(xm) {
      border-right: 1px solid $mid-grey;
      float: left;
      padding-right: 1rem;
      margin-bottom: 1rem;
      margin-right: 1rem;
    }
  }
  .field-name-field-reg-status {
    margin-bottom: 1rem;  
    @include breakpoint(xm) {
      float: left;
      margin-bottom: 0;
    }
    .field-items {
      color: $orange;
    }
  }
  .nav-buttons {
    background: $light-grey;
    padding: 1rem;
    margin-bottom: 1rem;
    h2 {
      margin-bottom: 0.5rem;
      &:after {
        content: ":";
      }
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      list-style: none;
      display: inline-block;
      margin-right: 1rem;
    }
    input {
      display: inline-block;
      margin-right: 0.5rem;
    }
  }
  .webform-client-form {
    border: 1px solid $mid-grey;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .webform-component--addon-room {
    label {
      display: inline;
    }
  }
}

.pane-event-registration-upcoming-trips-pane {
  .views-field-title {
    font-size: $h3-font-size;
    margin-bottom: 0.5rem;
  }
}
/* Slick carousel listing */
.carousel {
  position: relative;
  padding: 1.5rem 0 3.5rem;

  @include breakpoint(md) {
    padding-bottom: 1.5rem;
  }

  .slick__arrow {
    position: absolute;
    top: 0;
    right: 1.25rem;
    bottom: 0;
    left: 1.25rem;
    width: auto;
    height: auto;

    button {
      @extend %slick__arrow;

      &.slick-disabled:hover {
        cursor: default;

        &::before {
          color: $wv-orange;
        }
      }
    }

    .slick-arrow {
      visibility: hidden;

      @include breakpoint(md) {
        visibility: visible;
      }
    }

    .slick-dots {
      position: absolute;
      bottom: 0.5rem;
    }
  }
  .slick__slider {
    margin-bottom: 0;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    @include breakpoint(sm) {
      padding: 0 4rem;
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }

    @include breakpoint(md) {
      padding: 0;
    }

    .slick-list {
      @include breakpoint(sm) {
        padding: 0 4rem;
        margin: 0 -4rem;
      }
    }

    .slick-track {
      display: flex;
      align-items: stretch;
    }

    .slick__slide {
      padding: 0 0.5rem;
      height: unset;

      @include breakpoint(sm) {
        padding: 0 0.75rem;
      }
    }

    .slide__content {
      height: 100%;
    }
  }

  /* Banner carousel */
  &.banner-carousel {
    padding-top: 0;
    padding-bottom: 3.5rem;
    .slick__arrow {
      .slick-arrow {
        display: none !important;
      }
    }
    .slick__slider {
      padding: 0 !important;
    }
    .media-card ~ a {
      line-height: 0;
      color: transparent;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
    }
    &:has(.slide--0:only-child) {
      padding-bottom: 0;
    }
  }
}

/* Flex listing */
.media-card-listing {
  .view-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    @include breakpoint(sm) {
      flex-direction: row;
    }
  }
  &-3 .views-row {
    flex-basis: 100%;
    @include breakpoint(sm) {
      flex-basis: calc((100% - 1*2rem) / 2); /* 2 internal gaps */
    }
    @include breakpoint(md) {
      flex-basis: calc((100% - 2*2rem) / 3); /* 2 internal gaps */
    }
  }
  &-4 .views-row {
    flex-basis: 100%;
    @include breakpoint(sm) {
      flex-basis: calc((100% - 1*2rem) / 2); /* 3 internal gaps */
    }
    @include breakpoint(md) {
      flex-basis: calc((100% - 3*2rem) / 4); /* 3 internal gaps */
    }
  }
  &-5 .views-row {
    flex-basis: 100%;
    @include breakpoint(sm) {
      flex-basis: calc((100% - 1*2rem) / 2); /* 4 internal gaps */
    }
    @include breakpoint(md) {
      flex-basis: calc((100% - 2*2rem) / 3); /* 2 internal gaps */
    }
    @include breakpoint(lg) {
      flex-basis: calc((100% - 4*2rem) / 5); /* 4 internal gaps */
    }
  }
}

/* Media card styling override based on the carousel type */
.media-card {
  .carousel &, .media-card-listing & {
    height: 100%;
    max-width: 280px;

    @include breakpoint(sm) {
      max-width: 395px;
    }
  }
  .carousel-4 &, .media-card-listing-4 &,
  .carousel-5 &, .media-card-listing-5 & {
    .field-name-title h3 {
      /* h4 font size */
      font-size: 1.188rem !important;
      line-height: 1.75rem !important;
      @include breakpoint(sm) {
        font-size: 1.563rem !important;
        line-height: 1.875rem !important;
      }
    }
    .field-name-field-media-card-cta {
      a {
        font-size: 0.875rem;
      }
    }
  }
  .banner-carousel & {
    border-radius: 0;
    position: relative;
    max-width: unset;
    width: 100%;
    height: 16.5rem;
    @include breakpoint(md) {
      height: 25rem;
    }
    .media-card__media {
      border-radius: 0;
      aspect-ratio: unset;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .media-card__details {
      display: none;
    }
  }
}

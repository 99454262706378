.pane-careers-panel-pane-1 {
  .pane-title {
    padding-top: 1.5rem;
  }
}

.view-careers {
  li.views-row {
    margin-bottom: 1.5rem;
    color: $orange;
    a {
      color: $orange;
    }
  }
}

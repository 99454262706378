// PAGINATION
// --------------------------------------------------
.pager {
  margin-left: 0;
  padding-inline-start: 0;
  text-align: center;
  li {
    display: inline-block;
    list-style: none;
    margin: 0 0.5rem;
  }
  a {
    color: $dark-grey;
    &:hover {
      color: $orange;
    }
  }
  .pager-current {
    border: 1px solid $orange;
    padding: 3px 8px;
    color: $orange;
  }
  &.pager-load-more {
    a {
      @extend .cta-transparent;
      &::after {
        content: '';
      }
    }
  }
}
